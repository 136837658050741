import * as _ from 'lodash';

import type {
  FilterSectionRecordModelType,
  MenuSortOptionRecordModelType,
} from '@/models/api';
import type { ProductWhereInput } from '@/models/api/RootStore.base';
import type { SelectedFiltersModelType } from '@/models/product/filters';

export type FilterLookup = {
  singleKey: string;
  multiKey: string;
  valueLookup: Record<string, any>;
};

export const createFilterLookup = (combinedFilters: any) => {
  const filterLookup: Record<string, FilterLookup> = {};

  combinedFilters?.forEach((value: FilterSectionRecordModelType) => {
    // @ts-ignore
    const filter = value.values[0];
    const subFilter = filter.subValues[0];
    filterLookup[filter.key] = {
      singleKey: filter.singleKey,
      multiKey: filter.multiKey,
      valueLookup: _.keyBy(value.values, 'value'),
    };
    if (subFilter) {
      filterLookup[subFilter.key] = {
        singleKey: subFilter.singleKey,
        multiKey: subFilter.multiKey,
        valueLookup: _.keyBy(value.values, 'value'),
      };
    }
  });
  return filterLookup;
};

export const createFilterGraphQLQuery = (
  filters: SelectedFiltersModelType,
  filterKeyLookup: Record<keyof SelectedFiltersModelType, FilterLookup>,
): ProductWhereInput => {
  const query: any = {};
  const filterKeys = Object.keys(filters) as (keyof SelectedFiltersModelType)[];

  filterKeys.forEach((key) => {
    let operator;
    const values = filters[key];
    // if (key === 'showOnlyLiked') {
    //   return;
    // }
    const { valueLookup } = filterKeyLookup[key] ?? {};
    if (_.isEmpty(valueLookup)) {
      return;
    }

    const parsedValues = values
      .filter((value: string) => value !== '')
      .map((value: string) => {
        return valueLookup[value].data.value;
      })
      .filter((dataValue: string) => dataValue !== '');

    if (!parsedValues.length) {
      return;
    }

    if (parsedValues.length > 1) {
      operator = filterKeyLookup[key].multiKey;
      query[key] = {
        [operator]: _.flatten(parsedValues),
      };
      return;
    }
    if (parsedValues[0] === 'isNew') {
      query.isNew = true;
      return;
    }
    if (parsedValues[0] === 'isLiked') {
      return;
    }
    if (parsedValues[0] === 'hasSubProducts') {
      query.hasSubProducts = true;
      return;
    }
    operator = filterKeyLookup[key].singleKey;
    if (Array.isArray(parsedValues[0])) {
      operator = filterKeyLookup[key].multiKey;
    }
    query[key] = {
      ...(operator && { [operator]: parsedValues[0] }),
      ...(!operator && parsedValues[0]),
    };
  });
  return query as ProductWhereInput;
};

export const createSortGraphQLQuery = (
  sort: string,
  sortKeyLookup: Record<string, MenuSortOptionRecordModelType>,
) => {
  const parsedData = sortKeyLookup[sort]?.data ?? [];
  if (Array.isArray(parsedData)) {
    return parsedData;
  }
  return [parsedData];
};
