// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { QueryBuilder } from "mst-gql"
import { CustomPlanCustomerAttributeConditionRecordModelType } from "./CustomPlanCustomerAttributeConditionRecordModel"
import { CustomPlanCustomerAttributeConditionRecordModelSelector, customPlanCustomerAttributeConditionRecordModelPrimitives } from "./CustomPlanCustomerAttributeConditionRecordModel.base"
import { CustomPlanInputConditionRecordModelType } from "./CustomPlanInputConditionRecordModel"
import { CustomPlanInputConditionRecordModelSelector, customPlanInputConditionRecordModelPrimitives } from "./CustomPlanInputConditionRecordModel.base"
import { CustomPlanJsonConditionRecordModelType } from "./CustomPlanJsonConditionRecordModel"
import { CustomPlanJsonConditionRecordModelSelector, customPlanJsonConditionRecordModelPrimitives } from "./CustomPlanJsonConditionRecordModel.base"

export type CustomPlanRuleModelConditionsFieldUnion = CustomPlanCustomerAttributeConditionRecordModelType | CustomPlanInputConditionRecordModelType | CustomPlanJsonConditionRecordModelType

export class CustomPlanRuleModelConditionsFieldModelSelector extends QueryBuilder {
  customPlanCustomerAttributeConditionRecord(builder?: string | CustomPlanCustomerAttributeConditionRecordModelSelector | ((selector: CustomPlanCustomerAttributeConditionRecordModelSelector) => CustomPlanCustomerAttributeConditionRecordModelSelector)) { return this.__inlineFragment(`CustomPlanCustomerAttributeConditionRecord`, CustomPlanCustomerAttributeConditionRecordModelSelector, builder) }
  customPlanInputConditionRecord(builder?: string | CustomPlanInputConditionRecordModelSelector | ((selector: CustomPlanInputConditionRecordModelSelector) => CustomPlanInputConditionRecordModelSelector)) { return this.__inlineFragment(`CustomPlanInputConditionRecord`, CustomPlanInputConditionRecordModelSelector, builder) }
  customPlanJsonConditionRecord(builder?: string | CustomPlanJsonConditionRecordModelSelector | ((selector: CustomPlanJsonConditionRecordModelSelector) => CustomPlanJsonConditionRecordModelSelector)) { return this.__inlineFragment(`CustomPlanJsonConditionRecord`, CustomPlanJsonConditionRecordModelSelector, builder) }
}
export function selectFromCustomPlanRuleModelConditionsField() {
  return new CustomPlanRuleModelConditionsFieldModelSelector()
}

// provides all primitive fields of union member types combined together
export const customPlanRuleModelConditionsFieldModelPrimitives = selectFromCustomPlanRuleModelConditionsField().customPlanCustomerAttributeConditionRecord(customPlanCustomerAttributeConditionRecordModelPrimitives).customPlanInputConditionRecord(customPlanInputConditionRecordModelPrimitives).customPlanJsonConditionRecord(customPlanJsonConditionRecordModelPrimitives)