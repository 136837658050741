import { useEffect, useState } from 'react';

import type { ProfileStageRecordModelType } from '@/models/api';

import type { ProfileStageAttribute } from './helper';
import { extractAttributes, validateAttributes } from './helper';
import ProfileStageFooter from './ProfileStageFooter';
import ProfileStageHeader from './ProfileStageHeader';
import ProfileStageSection from './ProfileStageSection';
import ProfileStageStructuredText from './ProfileStageStructuredText';

export type ProfileStageFormProps = {
  onSubmit: (data: ProfileStageAttribute[]) => Promise<void>;
  onSkip: () => void;
  data: ProfileStageRecordModelType;
  loading?: boolean;
  isMandatory?: boolean;
};

const ProfileStageForm: React.FC<ProfileStageFormProps> = ({
  onSubmit,
  onSkip,
  data,
  loading: loadingOverride,
  isMandatory,
}) => {
  const sections = data.sections || [];
  const [attributes, setAttributes] = useState<ProfileStageAttribute[]>(
    extractAttributes(data),
  );
  const [loading, setLoading] = useState(false);
  const handleChange = (name: string, value: any) => {
    const newAttributes = attributes.filter((attr) => attr.name !== name);
    setAttributes([...newAttributes, { name, value }]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(attributes);
    setLoading(false);
  };

  const isDisabled = !validateAttributes(data, attributes);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data.name]);

  return (
    <div className="flex h-full flex-col justify-between">
      <ProfileStageHeader
        onClose={isMandatory ? undefined : onSkip}
        percentage={data.percentage || 0}
        title={data.headerTitle || '<span/>'}
      />
      <div className="flex flex-1 flex-col gap-6 px-6 py-5">
        <div className="font-interSemibold text-4xl">
          {data.title || "We'd Love to Hear From you!"}
        </div>
        <div className="flex flex-1 grow flex-col gap-8">
          {sections.map((section) => (
            <ProfileStageSection
              key={section.id}
              section={section}
              onChange={handleChange}
            />
          ))}
        </div>
        {data.footer ? (
          <ProfileStageStructuredText content={data.footer} />
        ) : null}
      </div>
      <ProfileStageFooter
        title={data.footerButton || 'Submit'}
        detail={data.footerDetail || '<span/>'}
        disabled={isDisabled}
        loading={loading || loadingOverride}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default ProfileStageForm;
