import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import animationSuccess from '@/assets/animation-success.json';

import ProfileStageFooter from './ProfileStageFooter';
import ProfileStageHeader from './ProfileStageHeader';

// Dynamically import Lottie to prevent SSR issues
const Lottie = dynamic(() => import('react-lottie-player'), { ssr: false });

export type ProfileStageCompleteProps = {
  onConfirm: () => void;
  title: string;
  detail: string;
};

const ProfileStageComplete: React.FC<ProfileStageCompleteProps> = ({
  onConfirm,
  title,
  detail,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex h-full flex-col justify-between">
      <ProfileStageHeader hideProgressBar transparent />
      <div className="flex flex-1 flex-col items-center gap-12 overflow-hidden p-6">
        <Lottie
          animationData={animationSuccess}
          style={{ width: 155, height: 155, alignSelf: 'center' }}
          loop={false}
          play
        />
        <div className="text-balance text-center font-interBold text-4xl">
          {title}
        </div>
        <div className="mb-20" dangerouslySetInnerHTML={{ __html: detail }} />
      </div>
      <ProfileStageFooter title={'Close and continue'} onClick={onConfirm} />
    </div>
  );
};

export default ProfileStageComplete;
