export const SINGLE_DECIMAL_THRESHOLD = 100000;
export const MIN_WEIGHT = 45;
export const MAX_WEIGHT = 300;
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again!';
export const INVALID_ITEM_ERROR_MESSAGE =
  'Your cart has been reset because of an invalid item.';
export const NOT_FOUND_STATUS = 404;
export const DEFAULT_TIMEZONE = 'Australia/Sydney';
export const WLP_WEIGHT_THRESHOLD = 70;
export const CHECK_DATA_VERSION_INTERVAL = 1000 * 60 * 2; // 2 minutes
export const CMS_FETCH_LIMIT = 100;
export const GQL_QUERY_OPTIONS = {
  fetchPolicy: 'network-only',
};
export const CHATBOT_VERSION =
  process.env.NEXT_PUBLIC_CHATBOT_VERSION || 'zendesk';

export enum LoadingState {
  INITIAL = 'initial',
  LOADING = 'loading',
  DONE = 'done',
  ERROR = 'error',
}

export enum ToastEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum Action {
  SWAP_ITEM = 'swap-item',
  ADD_EXTRA = 'add-extra',
  ADD_ITEM = 'add-item',
  VIEW = 'view',
  VIEW_WLP = 'view-wlp',
  NEW_PLAN = 'new-plan',
}

export const DRAFT_CART_ACTIONS = [
  Action.VIEW,
  Action.VIEW_WLP,
  Action.ADD_EXTRA,
  Action.NEW_PLAN,
  Action.SWAP_ITEM,
];

export const FULL_PDP_PLAN_ACTIONS = [
  Action.VIEW,
  Action.VIEW_WLP,
  Action.NEW_PLAN,
];

export const ACTIVE_LEVEL_MAP = {
  1: 'LIGHT',
  2: 'ACTIVE',
  3: 'VERY',
};

export enum Mode {
  VIEW_PLAN = 'view-plan',
  EDIT_CART = 'edit-cart',
  EDIT_SUBSCRIPTION = 'edit-subscription',
  NEW_PLAN = 'new-plan',
}

export enum WlpPlan {
  FULL = 'wlp-full',
  LITE = 'wlp-lite',
  FIXED_FEMALE = 'wlp-fixed-female',
  FIXED_MALE = 'wlp-fixed-male',
  FULL_FEMALE = 'wlp-full-female',
  FULL_MALE = 'wlp-full-male',
}

export type OptionType = 'single-choice' | 'multiple-choice';

export type Position = { x: number; y: number };

export type CalorieKey = 'aboveThreshold' | 'belowThreshold';

export enum Answer {
  YES = 'yes',
  NO = 'no',
}

export const ActiveStatus = ['Lightly Active', 'Active', 'Very Active'];

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const days = [
  { id: 0, day: 'Mon' },
  { id: 1, day: 'Tue' },
  { id: 2, day: 'Wed' },
  { id: 3, day: 'Thu' },
  { id: 4, day: 'Fri' },
  { id: 5, day: 'Sat' },
  { id: 6, day: 'Sun' },
];

export const ACTIVE_LEVEL_TYPE = 'activeLevelType';

export const getTimeZone = (stateCode: string) => {
  let timeZone = 'Australia/NSW';
  switch (stateCode) {
    case 'WA':
      timeZone = 'Australia/West';
      break;
    case 'QLD':
      timeZone = 'Australia/Queensland';
      break;
    case 'SA':
      timeZone = 'Australia/South';
      break;
    case 'TAS':
      timeZone = 'Australia/Tasmania';
      break;
    case 'VIC':
      timeZone = 'Australia/Victoria';
      break;
    case 'NT':
      timeZone = 'Australia/North';
      break;
    case 'ACT':
      timeZone = 'Australia/ACT';
      break;
    default:
      break;
  }
  return timeZone;
};

export const MACRO_TRANSLATIONS = {
  c: 4,
  p: 4,
  f: 9,
  fibre: 0,
};

export enum AgeRange {
  '18-25' = '18-25',
  '26-39' = '26-39',
  '40-49' = '40-49',
  '49+' = '49+',
}

export enum Exercise {
  'I don’t really exercise' = '0',
  'I exercise 1-2 days per week' = '1-2',
  'I exercise 4+ days per week' = '4+',
}

export enum PlanProductCategory {
  MEALS = 'Meals',
  BREKKIE = 'Breakfast',
  SNACKS = 'Snacks & Treats',
  DRINKS = 'Drinks & Powders',
}

export enum SwapCategory {
  DEFAULT = 'default',
  MEALS = 'meals',
  BREKKIE = 'brekkie',
  SNACKS = 'snacks',
  DRINKS = 'drinks',
  EXTRA = 'extra',
  SOUPS = 'soups',
}

export const PLAN_PRODUCT_CATEGORY_SECTION = [
  { category: SwapCategory.MEALS, title: PlanProductCategory.MEALS },
  { category: SwapCategory.BREKKIE, title: PlanProductCategory.BREKKIE },
  { category: SwapCategory.SNACKS, title: PlanProductCategory.SNACKS },
  { category: SwapCategory.DRINKS, title: PlanProductCategory.DRINKS },
];

export const CATEGORY_MAP = {
  MEALS: SwapCategory.MEALS,
  SOUPS: SwapCategory.MEALS,
  BREKKIE: SwapCategory.BREKKIE,
  SNACKS: SwapCategory.SNACKS,
  DRINKS: SwapCategory.DRINKS,
};

export const SWAP_CONFIG = [
  {
    category: SwapCategory.MEALS,
    displayCategories: ['Meals'],
  },
  {
    category: SwapCategory.BREKKIE,
    displayCategories: ['Breakfast'],
  },
  {
    category: SwapCategory.SNACKS,
    displayCategories: ['Soups', 'Snacks', 'Drinks'],
  },
  {
    category: SwapCategory.DRINKS,
    displayCategories: ['Soups', 'Snacks', 'Drinks'],
  },
  {
    category: SwapCategory.EXTRA,
    displayCategories: ['Meals', 'Soups', 'Breakfast', 'Snacks', 'Drinks'],
  },
];

export type SwapConfigType = keyof typeof SWAP_CONFIG;
export type PaymentMethodTypes = {
  [key: string]: string;
};
export const paymentMethods: PaymentMethodTypes = {
  CREDIT_CARD: 'Credit Card',
  GIFT: 'Gift Card',
  PayPal: 'Pay Pal',
  ZIPPAY: 'Zip',
  CREDIT: 'Credit',
  GOOGLEPAY: 'Google Pay',
  APPLEPAY: 'Apple Pay',
};

export enum DeliveryStatus {
  CURRENT = 'Processed',
  NEXT = 'Delivery On',
  FUTURE = 'Future Delivery',
}

export enum Currency {
  AUD = 'AUD',
  USD = 'USD',
}

export const goalName = {
  CCTRL: 'Calorie Control',
  MGAIN: 'Muscle Gain',
};

export const goalBasedQuestions = {
  gender: {
    question: 'What is your gender?',
    step: 1,
  },
  ageRange: {
    question: 'My age range is?',
    step: 2,
  },
  weight: {
    question: 'Current weight?',
    step: 3,
  },
  exercise: {
    question: 'Activity levels?',
    step: 4,
  },
  numberOfDays: {
    question: 'Days per meal plan?',
    step: 5,
  },
};

export const categoryOrder = [
  'MEALS',
  'BREKKIE',
  'SOUPS',
  'SNACKS',
  'DRINKS',
  'GIFT_CERTIFICATE',
];

export const LARGE_COUNTS = [
  { unit: '', threshold: 0 },
  { unit: 'K', threshold: 1e3 },
  { unit: 'M', threshold: 1e6 },
  { unit: 'B', threshold: 1e9 },
  { unit: 'T', threshold: 1e12 },
];

export const EnableFacebookLogin = false;
export const merchantName = 'My Muscle Chef';
export enum Experiments {
  APPLEPAY = 'test5_applepay_express_checkout',
  PREFILLCARD = 'menu_prefill_card',
  MEGANAV = 'web_mega_nav',
  PROFILE_STAGE = 'profile_stage',
  PROFILE_STAGE_MANDATORY = 'profile_stage_mandatory',
  SUBSCRIPTION_SHIP_NOW = 'subscription_ship_now',
}

export enum TipKey {
  ORDER_CANCELLATION = 'order-cancellation',
  GET_IT_SOONER_PENDING = 'get-it-sooner-pending',
  GET_IT_SOONER_PAID = 'get-it-sooner-paid',
  ORDER_DELIVERY = 'order-delivery',
  SUBSCRIPTION_DELIVERY = 'subscription-delivery',
  DELIVERY_DATE = 'delivery-date',
  DELIVERY_TIME = 'delivery-time',
  UNLOCK_EXCLUSIVE_BENEFITS = 'unlock-exclusive-benefits',
  SUBSCRIPTION_BENEFITS = 'subscription-benefits',
  SUBSCRIPTION_PAUSE_BENEFITS = 'subscription-pause-benefits',
  SUBSCRIPTION_CANCELLATION = 'subscription-cancellation',
  SUBSCRIPTION_CANCEL_NOTES = 'subscription-cancel-notes',
  SUBSCRIPTION_RESCHEDULE_BENEFITS = 'subscription-reschedule-benefits',
  SUBSCRIPTION_RESCHEDULE_CONFIRM = 'subscription-reschedule-confirm',
  SUBSCRIPTION_RESCHEDULE_NOTES = 'subscription-reschedule-notes',
  RECURRING_DELIVERY_HEADING = 'recurring-delivery-heading',
  RECURRING_DELIVERY_TIPS = 'recurring-delivery-tips',
  RECURRING_DELIVERY_TIPS_LG = 'recurring-delivery-tips-lg',
}
export type ColorType = {
  hex: string;
};

export const FEATURE_FLAGS = {
  CONTROL: 'control',
  NEW_UPSELLS: 'new_upsells', //  new sidebar feature
  NEW_ORDER_FREQUENCY: 'new_order_frequency', // new order frequency feature
};

export enum MultiOrderStatus {
  REDEEMED = 'Redeemed',
  UNLOCKED = 'Unlocked',
  Locked = 'Locked',
}
export enum SearchState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  COMPLETE = 'complete',
  NULL = 'null',
  PENDING = 'pending',
  CANCELLING = 'cancelling',
}

export enum SearchModes {
  SWAP_EDIT_CART = 'swap-edit-cart',
  SWAP_EDIT_CART_SEARCH = 'swap-edit-cart-search',
  SWAP_EDIT_SUBSCRIPTION_SEARCH = 'swap-edit-subscription-search',
  SWAP_EDIT_SUBSCRIPTION = 'swap-edit-subscription',
  SWAP_SEARCH = 'swap-search',
  Swap = 'swap',
  MENU_SEARCH = 'menu-search',
  Menu = 'menu',
  EDIT_SUBSCRIPTION = 'edit-subscription',
  EDIT_SUBSCRIPTION_SEARCH = 'edit-subscription-search',
  NULL = 'null',
}

export enum SwapStates {
  SWAP_PENDING = 'swap-pending',
}

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_DISPLAY_FORMAT = 'D MMMM, YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const MONTHS_LOOKAHEAD = 6;
