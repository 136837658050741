import { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import _ from 'lodash';

export const ProductRestrictionLimitsModel = types
  .model('ProductRestrictionLimits')
  .props({
    products: types.optional(types.array(types.string), []),
    quantityLimit: types.optional(types.number, 0),
    errorMessage: types.optional(types.string, ''),
  })
  .views((self) => ({
    containsProduct(sku: string): boolean {
      return _.includes(self.products, sku);
    },
  }));

export type ProductRestrictionLimitsType = Instance<
  typeof ProductRestrictionLimitsModel
>;
export interface ProductRestrictionLimits
  extends ProductRestrictionLimitsType {}
