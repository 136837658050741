// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CartPlanModel, CartPlanModelType } from "./CartPlanModel"
import { CartPlanModelSelector } from "./CartPlanModel.base"
import { CustomPlanProductRangeEnumType } from "./CustomPlanProductRangeEnum"
import { GenderEnumType } from "./GenderEnum"
import { GoalBasedPlanModelOrderBy } from "./GoalBasedPlanModelOrderByEnum"
import { GoalBasedPlanRecordModel, GoalBasedPlanRecordModelType } from "./GoalBasedPlanRecordModel"
import { GoalBasedPlanRecordModelSelector } from "./GoalBasedPlanRecordModel.base"
import { GoalEnumType } from "./GoalEnum"
import { MessageRtoModel, MessageRtoModelType } from "./MessageRtoModel"
import { MessageRtoModelSelector } from "./MessageRtoModel.base"
import { ProductProteinEnumType } from "./ProductProteinEnum"
import { GoalBasedPlanModelFilterRemoteRelRomecms } from "./RootStore.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { RootStoreType } from "./index"


/**
 * GeneratedPlanBase
 * auto generated base class for the model GeneratedPlanModel.
 *
 * Generated Plan
 */
export const GeneratedPlanModelBase = ModelBase
  .named('GeneratedPlan')
  .props({
    __typename: types.optional(types.literal("GeneratedPlan"), "GeneratedPlan"),
    _id: types.identifier,
    bucket: types.union(types.undefined, types.null, types.number),
    cms: types.union(types.undefined, types.null, types.late((): any => GoalBasedPlanRecordModel)),
    createdAt: types.union(types.undefined, types.frozen()),
    gender: types.union(types.undefined, types.null, GenderEnumType),
    goal: types.union(types.undefined, GoalEnumType),
    key: types.union(types.undefined, types.string),
    message: types.union(types.undefined, types.null, types.late((): any => MessageRtoModel)),
    numberOfDays: types.union(types.undefined, types.null, types.number),
    numberOfMeals: types.union(types.undefined, types.null, types.number),
    plan: types.union(types.undefined, types.late((): any => CartPlanModel)),
    proteinTypes: types.union(types.undefined, types.null, types.array(ProductProteinEnumType)),
    ranges: types.union(types.undefined, types.null, types.array(CustomPlanProductRangeEnumType)),
    updatedAt: types.union(types.undefined, types.frozen()),
    weight: types.union(types.undefined, types.null, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GeneratedPlanModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get bucket() { return this.__attr(`bucket`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get gender() { return this.__attr(`gender`) }
  get goal() { return this.__attr(`goal`) }
  get key() { return this.__attr(`key`) }
  get numberOfDays() { return this.__attr(`numberOfDays`) }
  get numberOfMeals() { return this.__attr(`numberOfMeals`) }
  get proteinTypes() { return this.__attr(`proteinTypes`) }
  get ranges() { return this.__attr(`ranges`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get weight() { return this.__attr(`weight`) }
  cms(builder: string | GoalBasedPlanRecordModelSelector | ((selector: GoalBasedPlanRecordModelSelector) => GoalBasedPlanRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedPlanModelFilterRemoteRelRomecms | null), locale?: (SiteLocale | null), orderBy?: (GoalBasedPlanModelOrderBy | null)[] | null }) { return this.__child(`cms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GoalBasedPlanRecordModelSelector, builder) }
  message(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined) { return this.__child(`message`, MessageRtoModelSelector, builder) }
  plan(builder: string | CartPlanModelSelector | ((selector: CartPlanModelSelector) => CartPlanModelSelector) | undefined) { return this.__child(`plan`, CartPlanModelSelector, builder) }
}
export function selectFromGeneratedPlan() {
  return new GeneratedPlanModelSelector()
}

export const generatedPlanModelPrimitives = selectFromGeneratedPlan()._id.bucket.createdAt.gender.goal.key.numberOfDays.numberOfMeals.proteinTypes.ranges.updatedAt.weight
