import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modal } from '@/components/common/Modal/Modal';
import { useMediaQuery } from '@/hooks';
import { useStore } from '@/models/root-store';
import { Experiments } from '@/utils/constants';
import {
  checkVisiblePathnames,
  setProfileStageSkipUntil,
} from '@/utils/helpers';
import {
  trackFormSubmitted,
  trackFormViewed,
} from '@/utils/track/tracker.helper';

import type { ProfileStageAttribute } from './helper';
import ProfileStageComplete from './ProfileStageComplete';
import ProfileStageForm from './ProfileStageForm';

type ProfileStageModalProps = {};

const ProfileStageModal: React.FC<ProfileStageModalProps> = observer(() => {
  const store = useStore();
  const {
    customerStore: {
      updateCustomerProfileStageAttributes,
      customer,
      isLoggedIn,
    },
  } = store;
  const isMobile = useMediaQuery('(max-width:768px)');
  const customContentStyles = {
    maxWidth: 393,
    // Full-screen styles for mobile
    ...(isMobile && {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      top: '0',
      left: '0',
      transform: 'none',
      borderRadius: '0', // Optional: remove rounded corners for fullscreen
    }),
  };

  const showProfileStageModal = useFeatureFlagEnabled(
    Experiments.PROFILE_STAGE,
  );
  const [open, setOpen] = useState<boolean>(false);
  const [completeData, setCompleteData] = useState<{
    title: string;
    detail: string;
  }>({
    title: 'Your profile is 100% complete! 🎉',
    detail: `<div class="text-balance text-center font-interSemibold text-lg">You're officially entered into the draw to win<span class="font-interBold text-success-copy"> $50 off</span>. Best of luck!</div>`,
  });
  const [isMandatory, setIsMandatory] = useState<boolean>(false);
  const mandatoryFeaturePayload = useFeatureFlagPayload(
    Experiments.PROFILE_STAGE_MANDATORY,
  );

  useEffect(() => {
    if (showProfileStageModal === undefined || !isLoggedIn) {
      return;
    }
    if (!showProfileStageModal) {
      setOpen(false);
      setProfileStageSkipUntil();
      customer.updateIsProfileStageNotSkipped();
    }
  }, [showProfileStageModal, isLoggedIn]);

  useEffect(() => {
    const { incompleteProfileStage } = customer;
    if (!incompleteProfileStage) {
      return;
    }
    setCompleteData({
      title: incompleteProfileStage.completeTitle,
      detail: incompleteProfileStage.completeDetail,
    });

    // check the mandatory feature flag
    if (mandatoryFeaturePayload) {
      const mandatoryPathnames = _.get(
        mandatoryFeaturePayload,
        'mandatoryPathnames',
        '',
      ) as string;
      const isModalMandatory = checkVisiblePathnames(
        window.location.pathname,
        mandatoryPathnames,
        '',
      );
      setIsMandatory(isModalMandatory);
      if (isModalMandatory) {
        setOpen(true);
        trackFormViewed(incompleteProfileStage.name || '');
        return;
      }
    }

    // the business rule is to show the profile stage modal
    if (!customer.isProfileStageNotSkipped) {
      setOpen(false);
      return;
    }

    // check the pathname to show the modal
    const showModal = checkVisiblePathnames(
      window.location.pathname,
      incompleteProfileStage.visiblePathnames,
      incompleteProfileStage.invisiblePathnames,
    );

    setOpen(showModal);
    if (showModal) {
      trackFormViewed(incompleteProfileStage.name || '');
    }
  }, [
    window.location.pathname,
    customer.incompleteProfileStage?.name,
    customer.isProfileStageNotSkipped,
    mandatoryFeaturePayload,
  ]);

  const handleSkip = () => {
    setProfileStageSkipUntil();
    customer.updateIsProfileStageNotSkipped();
    setOpen(false);
  };

  const handleSubmit = async (data: ProfileStageAttribute[]) => {
    try {
      // update the profile stage to 2, may move this to server side
      const profileStage = customer.incompleteProfileStage?.name || '';
      const payload = [
        { name: 'profileStages', value: [profileStage] },
        { name: profileStage, value: data },
      ];
      await updateCustomerProfileStageAttributes(payload);
      trackFormSubmitted(profileStage);
    } catch (error) {
      toast.error('Failed to update profile stage');
    }
  };

  return open ? (
    <Modal
      isOpen={open}
      customContentStyles={customContentStyles}
      onRequestClose={() => {
        if (isMandatory) {
          return;
        }
        handleSkip();
      }}
      hideCloseIcon
    >
      <div className="h-screen max-h-full md:h-auto">
        {customer.incompleteProfileStage ? (
          <ProfileStageForm
            onSubmit={handleSubmit}
            onSkip={handleSkip}
            data={customer.incompleteProfileStage}
            isMandatory={isMandatory}
          />
        ) : (
          <ProfileStageComplete onConfirm={handleSkip} {...completeData} />
        )}
      </div>
    </Modal>
  ) : null;
});

export default ProfileStageModal;
