// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CustomerAddressModel, CustomerAddressModelType } from "./CustomerAddressModel"
import { CustomerAddressModelSelector } from "./CustomerAddressModel.base"
import { CustomerAttributeModel, CustomerAttributeModelType } from "./CustomerAttributeModel"
import { CustomerAttributeModelSelector } from "./CustomerAttributeModel.base"
import { CustomerPaymentModel, CustomerPaymentModelType } from "./CustomerPaymentModel"
import { CustomerPaymentModelSelector } from "./CustomerPaymentModel.base"
import { GenderEnumType } from "./GenderEnum"
import { ReferHistoryModel, ReferHistoryModelType } from "./ReferHistoryModel"
import { ReferHistoryModelSelector } from "./ReferHistoryModel.base"
import { SubscriptionInfoModel, SubscriptionInfoModelType } from "./SubscriptionInfoModel"
import { SubscriptionInfoModelSelector } from "./SubscriptionInfoModel.base"
import { RootStoreType } from "./index"


/**
 * CustomerProfileBase
 * auto generated base class for the model CustomerProfileModel.
 *
 * Customer Profile
 */
export const CustomerProfileModelBase = ModelBase
  .named('CustomerProfile')
  .props({
    __typename: types.optional(types.literal("CustomerProfile"), "CustomerProfile"),
    _id: types.identifier,
    addresses: types.union(types.undefined, types.null, types.array(types.late((): any => CustomerAddressModel))),
    attributes: types.union(types.undefined, types.array(types.late((): any => CustomerAttributeModel))),
    birthday: types.union(types.undefined, types.null, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    credit: types.union(types.undefined, types.number),
    daysSinceLastOrder: types.union(types.undefined, types.null, types.integer),
    email: types.union(types.undefined, types.string),
    firstName: types.union(types.undefined, types.string),
    gender: types.union(types.undefined, types.null, GenderEnumType),
    isGiftCardPurchaseAllowed: types.union(types.undefined, types.null, types.boolean),
    isInvalidEmail: types.union(types.undefined, types.null, types.boolean),
    isSubscriber: types.union(types.undefined, types.null, types.boolean),
    lastName: types.union(types.undefined, types.string),
    lastOrderAt: types.union(types.undefined, types.null, types.frozen()),
    numberOfOrders: types.union(types.undefined, types.number),
    paymentMethods: types.union(types.undefined, types.null, types.array(types.late((): any => CustomerPaymentModel))),
    phone: types.union(types.undefined, types.null, types.string),
    postcode: types.union(types.undefined, types.null, types.string),
    productLikes: types.union(types.undefined, types.null, types.array(types.string)),
    profileForm: types.union(types.undefined, types.null, types.frozen()),
    profileStage: types.union(types.undefined, types.number),
    promotionalCampaign: types.union(types.undefined, types.null, types.frozen()),
    referCode: types.union(types.undefined, types.string),
    referHistory: types.union(types.undefined, types.array(types.late((): any => ReferHistoryModel))),
    salesforceId: types.union(types.undefined, types.string),
    subscriptionInfo: types.union(types.undefined, types.null, types.late((): any => SubscriptionInfoModel)),
    unsubscribeEmailStatus: types.union(types.undefined, types.null, types.boolean),
    updatedAt: types.union(types.undefined, types.frozen()),
    weight: types.union(types.undefined, types.null, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CustomerProfileModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get birthday() { return this.__attr(`birthday`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get credit() { return this.__attr(`credit`) }
  get daysSinceLastOrder() { return this.__attr(`daysSinceLastOrder`) }
  get email() { return this.__attr(`email`) }
  get firstName() { return this.__attr(`firstName`) }
  get gender() { return this.__attr(`gender`) }
  get isGiftCardPurchaseAllowed() { return this.__attr(`isGiftCardPurchaseAllowed`) }
  get isInvalidEmail() { return this.__attr(`isInvalidEmail`) }
  get isSubscriber() { return this.__attr(`isSubscriber`) }
  get lastName() { return this.__attr(`lastName`) }
  get lastOrderAt() { return this.__attr(`lastOrderAt`) }
  get numberOfOrders() { return this.__attr(`numberOfOrders`) }
  get phone() { return this.__attr(`phone`) }
  get postcode() { return this.__attr(`postcode`) }
  get productLikes() { return this.__attr(`productLikes`) }
  get profileForm() { return this.__attr(`profileForm`) }
  get profileStage() { return this.__attr(`profileStage`) }
  get promotionalCampaign() { return this.__attr(`promotionalCampaign`) }
  get referCode() { return this.__attr(`referCode`) }
  get salesforceId() { return this.__attr(`salesforceId`) }
  get unsubscribeEmailStatus() { return this.__attr(`unsubscribeEmailStatus`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get weight() { return this.__attr(`weight`) }
  addresses(builder: string | CustomerAddressModelSelector | ((selector: CustomerAddressModelSelector) => CustomerAddressModelSelector) | undefined) { return this.__child(`addresses`, CustomerAddressModelSelector, builder) }
  attributes(builder: string | CustomerAttributeModelSelector | ((selector: CustomerAttributeModelSelector) => CustomerAttributeModelSelector) | undefined) { return this.__child(`attributes`, CustomerAttributeModelSelector, builder) }
  paymentMethods(builder: string | CustomerPaymentModelSelector | ((selector: CustomerPaymentModelSelector) => CustomerPaymentModelSelector) | undefined) { return this.__child(`paymentMethods`, CustomerPaymentModelSelector, builder) }
  referHistory(builder: string | ReferHistoryModelSelector | ((selector: ReferHistoryModelSelector) => ReferHistoryModelSelector) | undefined) { return this.__child(`referHistory`, ReferHistoryModelSelector, builder) }
  subscriptionInfo(builder: string | SubscriptionInfoModelSelector | ((selector: SubscriptionInfoModelSelector) => SubscriptionInfoModelSelector) | undefined) { return this.__child(`subscriptionInfo`, SubscriptionInfoModelSelector, builder) }
}
export function selectFromCustomerProfile() {
  return new CustomerProfileModelSelector()
}

export const customerProfileModelPrimitives = selectFromCustomerProfile()._id.birthday.createdAt.credit.daysSinceLastOrder.email.firstName.gender.isGiftCardPurchaseAllowed.isInvalidEmail.isSubscriber.lastName.lastOrderAt.numberOfOrders.phone.postcode.productLikes.profileForm.profileStage.promotionalCampaign.referCode.salesforceId.unsubscribeEmailStatus.updatedAt.weight
