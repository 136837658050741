import { AgeRanges, Goal, PlanCategory } from '@/models/api';

export const AGE_MAP = {
  [AgeRanges.LESS_THAN_18]: 'Less than 18',
  [AgeRanges.FROM_18_TO_25]: '18-25',
  [AgeRanges.FROM_26_TO_39]: '26-39',
  [AgeRanges.FROM_40_TO_49]: '40-49',
  [AgeRanges.GREATER_THAN_49]: '49+',
};

export const PACKS_COLLECTION_TYPE = {
  [Goal.MGAIN]: 'muscle-gain',
  [Goal.PERF]: 'performance',
  [Goal.CCTRL]: 'calorie-control',
  [Goal.WLOSS]: 'weight-loss',
};

export const GOAL_COLLECTION_TYPE = {
  musclegain: 'muscle-gain',
  performance: 'performance',
  caloriecontrol: 'calorie-control',
  weightloss: 'weight-loss',
};

export const PLAN_MAP = {
  musclegain: Goal.MGAIN,
  caloriecontrol: Goal.CCTRL,
  weightloss: Goal.WLOSS,
};

export const API_GOAL_MAP = {
  [Goal.MGAIN]: 'musclegain',
  [Goal.CCTRL]: 'caloriecontrol',
  [Goal.WLOSS]: 'weightloss',
  [Goal.SAVETIME]: 'savetime',
};

export const BUCKET_CONFIG = {
  [Goal.MGAIN]: {
    calConfig: { base: 36, coe: 4 },
    pConfig: { base: 0.16, coe: 0.01 },
    cConfig: { base: 0.46, coe: 0.01 },
    fConfig: { base: 0.38, coe: -0.02 },
  },
  [Goal.CCTRL]: {
    calConfig: { base: 29, coe: 3 },
    pConfig: { base: 0.26, coe: -0.01 },
    cConfig: { base: 0.39, coe: 0.01 },
    fConfig: { base: 0.35, coe: 0 },
  },
  [Goal.WLOSS]: {
    calConfig: { base: 28, coe: 2.7 },
    pConfig: { base: 0.3, coe: -0.005 },
    cConfig: { base: 0.35, coe: 0.005 },
    fConfig: { base: 0.35, coe: 0 },
  },
  [Goal.SAVETIME]: {
    calConfig: { base: 28, coe: 2.7 },
  },
  [Goal.HEALTHY]: {
    calConfig: { base: 28, coe: 2.7 },
  },
  [Goal.PERF]: {
    calConfig: { base: 28, coe: 2.7 },
  },
};

export const PLAN_ROUTE_MAP = {
  [PlanCategory.PACKS]: 'goalbased',
  [PlanCategory.WLP]: 'weight-loss/register',
};
