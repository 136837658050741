import { cast, types, Instance } from 'mobx-state-tree';

import { Payment, PaymentModel } from './payment';
import { Address, AddressModel } from './address';
import {
  AttributeModel,
  AuthRole,
  ProfileStageRecordModel,
  SubscriptionInfoModel,
} from '../api';
import { ReasonsModel } from './cancelReasons';
import _ from 'lodash';
import { checkProfileStageSkipUntil } from '@/utils/helpers';
import dayjs from 'dayjs';
import seedrandom from 'seedrandom';

export const CustomerModel = types
  .model('Customer')
  .props({
    _id: types.identifier,
    email: types.union(types.undefined, types.null, types.string),
    firstName: types.union(types.undefined, types.null, types.string),
    lastName: types.union(types.undefined, types.null, types.string),
    role: types.enumeration(Object.values(AuthRole)),
    phone: types.maybe(types.string),
    credit: types.optional(types.number, 0),
    referCode: types.union(types.undefined, types.null, types.string),
    addresses: types.optional(types.array(AddressModel), []),
    paymentMethods: types.optional(types.array(PaymentModel), []),
    numberOfOrders: types.optional(types.number, 0),
    profileStage: types.optional(types.number, 0),
    isSubscriber: types.union(types.boolean, types.undefined, types.null),
    unsubscribeEmailStatus: types.union(
      types.undefined,
      types.null,
      types.boolean,
    ),
    attributes: types.array(AttributeModel),
    productLikes: types.union(
      types.undefined,
      types.null,
      types.array(types.string),
    ),
    createdAt: types.union(types.undefined, types.frozen()),
    daysSinceLastOrder: types.union(types.undefined, types.number, types.null),
    profileForm: types.union(
      types.undefined,
      types.null,
      types.model('profileFormModel').props({
        title: types.maybe(types.string),
        uri: types.maybe(types.string),
        htmlTitle: types.maybe(types.string),
        htmlBody: types.maybe(types.string),
        htmlAction: types.maybe(types.string),
        typeformId: types.maybe(types.string),
        completedProfileStage: types.maybe(types.string),
      }),
    ),
    profileStageCms: types.union(
      types.undefined,
      types.null,
      types.array(ProfileStageRecordModel),
    ),
    isProfileStageNotSkipped: types.optional(types.boolean, false),
    isGiftCardPurchaseAllowed: types.union(
      types.boolean,
      types.undefined,
      types.null,
    ),
    promotionalCampaign: types.union(
      types.undefined,
      types.null,
      types.frozen(),
    ),
    subscriptionInfo: types.union(
      types.undefined,
      types.null,
      types.late((): any => SubscriptionInfoModel),
    ),
  })
  .views((self) => ({
    get payments() {
      return self.paymentMethods;
    },
    get defaultAddress(): Address | undefined {
      return (
        self.addresses.find((address) => address.preferred) ||
        _.first(self.addresses)
      );
    },
    get defaultPayment(): Payment | undefined {
      return this.payments.find((payment) => payment.preferred);
    },
    get isNewCustomer() {
      return !self.numberOfOrders;
    },
    get profileStages(): string[] {
      return (
        self.attributes.find((attr) => attr.name === 'profileStages')?.value ||
        []
      );
    },
    get recommendations() {
      const recommendationsAttr =
        self.attributes.find((attr) => attr.name === 'recommendations')
          ?.value || [];
      const flattenedRecommendations = _.flatMap(recommendationsAttr, 'value');
      const dateSeed = dayjs().format('YYYY-MM-DD');
      const shuffledRecommendations = _.sortBy(
        flattenedRecommendations,
        (sku) => seedrandom(sku + dateSeed)(),
      );
      return shuffledRecommendations;
    },
    get profileStageCompletedAt(): Date | undefined {
      const profileStageAttrs = _.filter(self.attributes, (attr) =>
        this.profileStages.includes(attr.name || ''),
      );
      const completedAts = _.map(
        profileStageAttrs,
        (attr) => _.find(attr.value, { name: 'completedAt' })?.value,
      );
      // Filter out undefined/null values and find the latest date
      return _.max(_.compact(completedAts.map((date) => new Date(date))));
    },
    get incompleteProfileStages() {
      return _.filter(
        self.profileStageCms,
        ({ name }) => !_.includes(this.profileStages, name),
      );
    },
    get incompleteProfileStage() {
      return _.first(this.incompleteProfileStages);
    },
    get hasIncompleteProfileStages() {
      return !!this.incompleteProfileStages.length;
    },
  }))
  .actions((self) => ({
    setAddresses(addresses: any) {
      self.addresses = cast(addresses);
    },
    setPayments(payments: any) {
      self.paymentMethods = cast(payments);
    },
    setProfileStageCms(profileStageCms: any) {
      self.profileStageCms = cast(profileStageCms);
    },
    updateIsProfileStageNotSkipped() {
      self.isProfileStageNotSkipped = checkProfileStageSkipUntil();
    },
  }));

export const CancelReasonsModel = types.model('Reasons').props({
  data: types.optional(types.array(ReasonsModel), []),
});

export interface Customer extends Instance<typeof CustomerModel> {}
export interface Reasons extends Instance<typeof CancelReasonsModel> {}
