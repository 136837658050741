import _ from 'lodash';
import {
  applySnapshot,
  clone,
  destroy,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
  flow,
  cast,
} from 'mobx-state-tree';
import { withRootStore } from '@/models/helpers/with-root-store';
import { GoalBasedModel } from '../goal-based/preset';
import { CartModel } from './cart';
import randomise from 'randomatic';
import { formatOrder } from '../helpers/order';

import type { Cart } from '@/models/cart/cart';
import type { Customer } from '@/models/customer/customer';
import {
  DEFAULT_ERROR_MESSAGE,
  GQL_QUERY_OPTIONS,
  INVALID_ITEM_ERROR_MESSAGE,
  LoadingState,
  Mode,
  NOT_FOUND_STATUS,
} from '@/utils/constants';
import {
  PLACE_ORDER_SELECTOR,
  GIFT_CARD_SELECTOR,
  UPSELLS_SELECTOR,
  PROMO_CODE_SELECTOR,
  UPDATE_ORDER_DELIVERY_DATE,
  PROMO_BANNER_SELECTOR,
} from '@/graphql/selectors';
import { OrderPlan } from './order-plan';
import { toast } from 'react-toastify';
import { CART_SELECTOR } from '@/graphql/selectors/cart';
import {
  formatAbandonCart,
  parseCart,
  formatCartOrderPlan,
} from '@/models/helpers/cart';
import dayjs from 'dayjs';
import { validateOrderPlan } from '../helpers/validation';
import * as Sentry from '@sentry/nextjs';
import { BannerModel } from './banner';
import {
  trackCouponApplied,
  trackCouponDenied,
  trackMealPlanGenerated,
  trackOrderFailed,
} from '@/utils/track/tracker.helper';
import { MealPlanModel } from '../meal-plan/preset';

export const CartStoreModel: any = types
  .model('CartStore')
  .props({
    cart: types.optional(CartModel, { cartId: randomise('Aa0', 48) }),
    draftCart: types.optional(CartModel, { cartId: randomise('Aa0', 48) }),
    goalBased: types.optional(GoalBasedModel, {}),
    banner: types.optional(BannerModel, {}),
    mealPlan: types.optional(MealPlanModel, {}),
  })
  .extend(withRootStore)
  .views((self) => ({
    get isEditingSubscription(): boolean {
      return self.draftCart?.mode === Mode.EDIT_SUBSCRIPTION;
    },
    get cartToUse(): Cart {
      if (this.isEditingSubscription) {
        return self.draftCart;
      }
      return self.cart;
    },
  }))
  .actions((self) => ({
    resetDraftCart() {
      destroy(self.draftCart);
    },
    resetCart() {
      const cart = CartModel.create({ cartId: randomise('Aa0', 48) });
      applySnapshot(self.cart, getSnapshot(cart));
    },
    resetBanner() {
      if (self.banner.banner) {
        destroy(self.banner.banner);
      }
    },

    postCart: flow(function* (cart: Cart = self.cart) {
      try {
        self.cart.setUpdatedAt(new Date());
        const { customerStore, productStore, api } = self.rootStore;
        if (!customerStore.isLoggedIn) return;
        if (_.isEmpty(productStore.products)) return;
        const payload = formatAbandonCart(cart, customerStore.customer);
        yield api.mutateUpsertCart({ cart: payload }, CART_SELECTOR).promise;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    getCart: flow(function* () {
      const lookup = self.rootStore.productStore.productsLookup;
      const productLoading = self.rootStore.productStore.productState;
      if (productLoading !== LoadingState.DONE) return false;
      if (!self.rootStore.customerStore.isLoggedIn) return false;
      try {
        const { cart } = yield self.rootStore.api.queryCart(
          {},
          CART_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        const { message } = cart;
        const data: any = parseCart(cart);
        const shouldRefreshCart =
          !self.cart.updatedAt ||
          self.cart.isEmpty ||
          (cart.updatedAt &&
            dayjs(new Date(cart.updatedAt)).isAfter(
              dayjs(new Date(self.cart.updatedAt)),
            ));

        // need to validate before applying
        data?.plans.forEach((plans: any) => {
          const isValid = validateOrderPlan(plans, lookup);
          if (!isValid) {
            throw new Error(INVALID_ITEM_ERROR_MESSAGE);
          }
        });

        if (shouldRefreshCart) {
          self.cart = cast({ ...getSnapshot(self.cart), ...data });
        }

        if (message?.message) {
          self.rootStore.generalStore.createNotification(message);
        }

        return shouldRefreshCart;
      } catch (error: any) {
        const status = _.get(
          error,
          'response.errors[0].extensions.exception.status',
        );
        if (status === NOT_FOUND_STATUS) {
          return false;
        }
        Sentry.captureException(error);
        destroy(self.cart);
        toast.error(error?.message || DEFAULT_ERROR_MESSAGE);
      }
    }),
    syncCart: flow(function* (cart: Cart = self.cart) {
      // app need to compare the last modified date
      //@ts-ignore
      const shouldRefreshCart = yield self.getCart();
      if (shouldRefreshCart === false) {
        //@ts-ignore
        yield self.postCart(cart);
      }
    }),
    updateOrderDeliveryDate: flow(function* (
      invoiceNumber: string,
      deliveryDate: string,
      timeSlot: string,
    ) {
      try {
        const { updateOrderDeliveryDate } =
          yield self.rootStore.api.mutateUpdateOrderDeliveryDate(
            { input: { invoiceNumber, deliveryDate, timeSlot } },
            UPDATE_ORDER_DELIVERY_DATE,
          ).promise;
        toast.success(updateOrderDeliveryDate.message.message);
        return updateOrderDeliveryDate;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        const errorMessage = errors[0].message;
        toast.error(errorMessage);
        return error;
      }
    }),
    placeOrder: flow(function* (customer: Customer, cart: Cart) {
      try {
        const payload = formatOrder(customer, cart);
        const { placeOrder } = yield self.rootStore.api.mutatePlaceOrder(
          payload,
          PLACE_ORDER_SELECTOR,
        ).promise;
        return placeOrder;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        const errorMessage = errors[0].message;
        toast.error(errorMessage);
        try {
          trackOrderFailed(
            cart,
            errorMessage,
            self.rootStore.productStore.productsFullList,
          );
        } catch (error) {
          // ignore error
        }
        return error;
      }
    }),
    applyGiftCard: flow(function* (code: string) {
      try {
        const { giftCard } = yield self.rootStore.api.queryGiftCard(
          { code },
          GIFT_CARD_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        return giftCard;
      } catch (error) {
        Sentry.captureException(error);
        return error;
      }
    }),
    applyPromoCode: flow(function* (cart: Cart, coupon: string) {
      const { customerStore } = self.rootStore;
      const payload = formatAbandonCart(cart, customerStore.customer);
      try {
        cart.isRefreshing += 1;
        const { basket } = yield self.rootStore.api.queryBasket(
          { cart: payload, coupon },
          PROMO_CODE_SELECTOR,
        ).promise;
        if (coupon !== '') {
          try {
            if (!_.isEmpty(basket.discounts)) {
              trackCouponApplied(cart.cartId, basket.discounts);
            } else {
              trackCouponApplied(cart.cartId, basket.shippingDiscount);
            }
          } catch (error) {
            // ignore error
          }
        }
        if (coupon) {
          cart.setCouponCode(coupon);
        }
        return basket;
      } catch (error: any) {
        const failedCoupon = coupon;
        cart.setCouponCode('');
        try {
          trackCouponDenied(cart.cartId, failedCoupon);
        } catch (error) {
          // ignore error
        }
        Sentry.captureException(error);
        const { errors } = error.response;
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      } finally {
        cart.isRefreshing -= 1;
      }
    }),
    getPromoBanner: flow(function* (code: string) {
      try {
        const variables = { filter: { code: { eq: code?.toUpperCase() } } };
        const { webPromoBannerCms } =
          yield self.rootStore.api.queryWebPromoBannerCms(
            variables,
            PROMO_BANNER_SELECTOR,
          ).promise;
        self.banner.banner = cast(webPromoBannerCms);
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    createDraftCart() {
      self.draftCart = clone(self.cart);
    },
    generatePlan(plan: OrderPlan, mode: Mode): void {
      if (!mode) {
        this.createDraftCart();
      }
      self.draftCart.addPlan(plan);
      self.draftCart.planIdToView = plan.planId;
      try {
        trackMealPlanGenerated(plan);
      } catch (error) {
        // ignore error
      }
    },
    generateWlpPlan(plans: OrderPlan[], days: number): void {
      const selectedDay = days || 7;
      const initialSelectedPlan = plans.find(
        (plan) =>
          plan.attributes.find((attr) => attr.name === 'numberOfDays')
            ?.value === selectedDay,
      )?.planId;
      if (!initialSelectedPlan) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        return;
      }
      this.createDraftCart();
      self.draftCart.addDraftPlans(plans);
      self.draftCart.planIdToView = initialSelectedPlan;
    },
    editPlan(planId: string, mode: Mode): void {
      if (!mode || mode === Mode.EDIT_CART) {
        this.createDraftCart();
      }
      self.draftCart.planIdToView = planId;
      self.draftCart.setMode(mode);
    },
    editWlpPlan(selectedPlan: OrderPlan, plans: OrderPlan[]): void {
      const snapShotPlan = getSnapshot(selectedPlan);
      const nod = snapShotPlan.attributes.find(
        (attr) => attr.name === 'numberOfDays',
      )?.value;
      const newPlans = [...plans];
      const planToReplace = newPlans.find(
        (plan) =>
          plan.attributes.find((attr) => attr.name === 'numberOfDays')
            ?.value === nod,
      )?.planId;
      const index = newPlans.findIndex((plan) => plan.planId === planToReplace);
      // @ts-ignore
      newPlans[index] = snapShotPlan;

      this.createDraftCart();
      // using the selected plan with the numberOfDays in attributes, replace the plan with the same numberOfDays
      self.draftCart.addDraftPlans(newPlans);
      // then will need to set the selected plan
      self.draftCart.planIdToView = selectedPlan.planId;
      self.draftCart.planIdToEdit = selectedPlan.planId;
      self.draftCart.setMode(Mode.EDIT_CART);
    },
    copyDraftCartToCart() {
      self.draftCart.action = undefined;
      self.draftCart.mode = undefined;
      self.draftCart.planIdToView = undefined;
      self.draftCart.resetDraftPlans();
      applySnapshot(self.cart, getSnapshot(self.draftCart));
    },
    copySubscriptionToDraftCart(subCart: Cart) {
      applySnapshot(self.draftCart, getSnapshot(subCart));
      self.draftCart.action = undefined;
      self.draftCart.mode = Mode.EDIT_SUBSCRIPTION;
    },
    getGuestUpSellsQuery: flow(function* (cart: Cart) {
      const plans = _.map(cart.plans, (plan: OrderPlan) =>
        formatCartOrderPlan(plan),
      );
      const address =
        cart.delivery?.deliveryAddress ??
        self.rootStore.customerStore.customer.defaultAddress;
      const delivery = address
        ? {
            deliveryAddress: {
              postcode: address.postcode,
              suburb: address.suburb,
            },
          }
        : null;
      const plansObj = { plans, delivery };
      try {
        const { guestCart } = yield self.rootStore.api.queryGuestCart(
          { cart: plansObj, limit: 4 },
          UPSELLS_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        return guestCart;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    getExtraBoxFee: flow(function* (cart: Cart) {
      const plans = _.map(cart.plans, (plan: OrderPlan) =>
        formatCartOrderPlan(plan),
      );
      const plansObj = { plans };
      try {
        const { guestCart } = yield self.rootStore.api.queryGuestCart(
          { cart: plansObj },
          UPSELLS_SELECTOR,
          { fetchPolicy: 'network-only' },
        ).promise;
        self.cart.setExtraBoxFee(guestCart.extraBoxFee);
        return guestCart.extraBoxFee;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
  }));

export type CartStoreType = Instance<typeof CartStoreModel>;
type CartStoreSnapshotType = SnapshotOut<typeof CartStoreModel>;
export interface CartStoreSnapshot extends CartStoreSnapshotType {}
