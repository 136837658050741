// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CustomPlanCustomerAttributeConditionRecordModel, CustomPlanCustomerAttributeConditionRecordModelType } from "./CustomPlanCustomerAttributeConditionRecordModel"
import { CustomPlanCustomerAttributeConditionRecordModelSelector } from "./CustomPlanCustomerAttributeConditionRecordModel.base"
import { CustomPlanInputConditionRecordModel, CustomPlanInputConditionRecordModelType } from "./CustomPlanInputConditionRecordModel"
import { CustomPlanInputConditionRecordModelSelector } from "./CustomPlanInputConditionRecordModel.base"
import { CustomPlanJsonConditionRecordModel, CustomPlanJsonConditionRecordModelType } from "./CustomPlanJsonConditionRecordModel"
import { CustomPlanJsonConditionRecordModelSelector } from "./CustomPlanJsonConditionRecordModel.base"
import { CustomPlanRuleModelConditionsFieldModelSelector } from "./CustomPlanRuleModelConditionsFieldModelSelector"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { MarketingContentApprovalWorkflowStageEnumType } from "./MarketingContentApprovalWorkflowStageEnum"
import { MenuHierarchyRecordModel, MenuHierarchyRecordModelType } from "./MenuHierarchyRecordModel"
import { MenuHierarchyRecordModelSelector } from "./MenuHierarchyRecordModel.base"
import { ProductRecordModel, ProductRecordModelType } from "./ProductRecordModel"
import { ProductRecordModelSelector } from "./ProductRecordModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/**
 * CustomPlanRuleRecordBase
 * auto generated base class for the model CustomPlanRuleRecordModel.
 *
 * Record of type Custom Plan Rule (custom_plan_rule)
 */
export const CustomPlanRuleRecordModelBase = ModelBase
  .named('CustomPlanRuleRecord')
  .props({
    __typename: types.optional(types.literal("CustomPlanRuleRecord"), "CustomPlanRuleRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _stage: types.union(types.undefined, MarketingContentApprovalWorkflowStageEnumType),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    active: types.union(types.undefined, types.null, types.frozen()),
    category: types.union(types.undefined, types.null, types.string),
    conditions: types.union(types.undefined, types.array(types.union(types.late((): any => CustomPlanCustomerAttributeConditionRecordModel), types.late((): any => CustomPlanInputConditionRecordModel), types.late((): any => CustomPlanJsonConditionRecordModel)))),
    createdAt: types.union(types.undefined, types.frozen()),
    id: types.union(types.undefined, types.frozen()),
    limit: types.union(types.undefined, types.null, types.frozen()),
    menuHierarchies: types.union(types.undefined, types.array(types.late((): any => MenuHierarchyRecordModel))),
    name: types.union(types.undefined, types.null, types.string),
    position: types.union(types.undefined, types.null, types.frozen()),
    products: types.union(types.undefined, types.array(types.late((): any => ProductRecordModel))),
    sort: types.union(types.undefined, types.null, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CustomPlanRuleRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _stage() { return this.__attr(`_stage`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get active() { return this.__attr(`active`) }
  get category() { return this.__attr(`category`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get limit() { return this.__attr(`limit`) }
  get name() { return this.__attr(`name`) }
  get position() { return this.__attr(`position`) }
  get sort() { return this.__attr(`sort`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  conditions(builder: string | CustomPlanRuleModelConditionsFieldModelSelector | ((selector: CustomPlanRuleModelConditionsFieldModelSelector) => CustomPlanRuleModelConditionsFieldModelSelector) | undefined) { return this.__child(`conditions`, CustomPlanRuleModelConditionsFieldModelSelector, builder) }
  menuHierarchies(builder: string | MenuHierarchyRecordModelSelector | ((selector: MenuHierarchyRecordModelSelector) => MenuHierarchyRecordModelSelector) | undefined) { return this.__child(`menuHierarchies`, MenuHierarchyRecordModelSelector, builder) }
  products(builder: string | ProductRecordModelSelector | ((selector: ProductRecordModelSelector) => ProductRecordModelSelector) | undefined) { return this.__child(`products`, ProductRecordModelSelector, builder) }
}
export function selectFromCustomPlanRuleRecord() {
  return new CustomPlanRuleRecordModelSelector()
}

export const customPlanRuleRecordModelPrimitives = selectFromCustomPlanRuleRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._stage._status._unpublishingScheduledAt._updatedAt.active.category.createdAt.limit.name.position.sort.updatedAt
