type ProfileStageSectionHeaderProps = {
  heading: string | undefined | null;
  subHeading?: string | undefined | null;
};

const ProfileStageSectionHeader: React.FC<ProfileStageSectionHeaderProps> = ({
  heading,
  subHeading,
}) => {
  return (
    <div>
      <div className="font-interMedium text-sm text-grey-dark">{heading}</div>
      {subHeading && (
        <div className="mt-1 text-xs text-grey-dark">{subHeading}</div>
      )}
    </div>
  );
};

export default ProfileStageSectionHeader;
