// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MessageStatusEnumType } from "./MessageStatusEnum"
import { MessageTypeEnumType } from "./MessageTypeEnum"
import { RootStoreType } from "./index"


/**
 * MessageRtoBase
 * auto generated base class for the model MessageRtoModel.
 *
 * Message RTO
 */
export const MessageRtoModelBase = ModelBase
  .named('MessageRto')
  .props({
    __typename: types.optional(types.literal("MessageRto"), "MessageRto"),
    cmsContentKey: types.union(types.undefined, types.null, types.string),
    message: types.union(types.undefined, types.string),
    status: types.union(types.undefined, types.null, MessageStatusEnumType),
    title: types.union(types.undefined, types.null, types.string),
    type: types.union(types.undefined, types.null, MessageTypeEnumType),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MessageRtoModelSelector extends QueryBuilder {
  get cmsContentKey() { return this.__attr(`cmsContentKey`) }
  get message() { return this.__attr(`message`) }
  get status() { return this.__attr(`status`) }
  get title() { return this.__attr(`title`) }
  get type() { return this.__attr(`type`) }
}
export function selectFromMessageRto() {
  return new MessageRtoModelSelector()
}

export const messageRtoModelPrimitives = selectFromMessageRto().cmsContentKey.message.status.title.type
