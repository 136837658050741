'use client';

import { motion } from 'motion/react';
import type React from 'react';

interface ProgressBarProps {
  percentage: number;
  className?: string;
  duration?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  className = '',
  duration = 0.8,
}) => {
  return (
    <div className={`h-1.5 w-full rounded-full bg-[#D9D9D9] ${className}`}>
      <motion.div
        className="h-full rounded-full"
        initial={{ width: 0 }}
        animate={{ width: `${percentage}%` }}
        transition={{
          duration,
          ease: 'easeOut',
        }}
        style={{
          background:
            'linear-gradient(90deg, #6CCB5F -26.46%, #2C7D4B 113.09%)',
        }}
      />
    </div>
  );
};

export default ProgressBar;
