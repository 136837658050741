// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CartModel, CartModelType } from "./CartModel"
import { CartModelSelector } from "./CartModel.base"
import { CustomPlanProductRange } from "./CustomPlanProductRangeEnum"
import { CustomerActivityModel, CustomerActivityModelType } from "./CustomerActivityModel"
import { CustomerActivityModelSelector } from "./CustomerActivityModel.base"
import { CustomerAddressModel, CustomerAddressModelType } from "./CustomerAddressModel"
import { CustomerAddressModelSelector } from "./CustomerAddressModel.base"
import { CustomerPaymentModel, CustomerPaymentModelType } from "./CustomerPaymentModel"
import { CustomerPaymentModelSelector } from "./CustomerPaymentModel.base"
import { CustomerProfileModel, CustomerProfileModelType } from "./CustomerProfileModel"
import { CustomerProfileModelSelector } from "./CustomerProfileModel.base"
import { Gender } from "./GenderEnum"
import { GeneratedPlanModel, GeneratedPlanModelType } from "./GeneratedPlanModel"
import { GeneratedPlanModelSelector } from "./GeneratedPlanModel.base"
import { Goal } from "./GoalEnum"
import { MessageRtoModel, MessageRtoModelType } from "./MessageRtoModel"
import { MessageRtoModelSelector } from "./MessageRtoModel.base"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { ProductProtein } from "./ProductProteinEnum"
import { RedeemCreditRtoModel, RedeemCreditRtoModelType } from "./RedeemCreditRtoModel"
import { RedeemCreditRtoModelSelector } from "./RedeemCreditRtoModel.base"
import { CartInput, CartPlanInput, CreatePaymentMethodInput, CustomerActivityDto, CustomerAddressInput, CustomerCreditInput, ExtraItemDto, GuestCartInput, LikeProductInput, NotificationEventDto, NotificationTokenDeleteDto, NotificationTokenDto, OrderDeliveryDateInput, PlaceOrderInput, SubscriptionCancelInput, SubscriptionDeliveryDateInput, SubscriptionDeliveryInput, SubscriptionFrequencyInput, SubscriptionFuturesInput, SubscriptionPaymentInput, SubscriptionPlansInput, SubscriptionShipNowInput, SwapWeeklyPlanDto, UpdateCustomerAttributesInput, UpdateCustomerInput, UpdateCustomerProfileStageInput, UpdateWeeklyPlanDto, VerifyCustomerIdentifierCodeInput } from "./RootStore.base"
import { SubscriptionEntityModel, SubscriptionEntityModelType } from "./SubscriptionEntityModel"
import { SubscriptionEntityModelSelector } from "./SubscriptionEntityModel.base"
import { UpdateOrderResponseModel, UpdateOrderResponseModelType } from "./UpdateOrderResponseModel"
import { UpdateOrderResponseModelSelector } from "./UpdateOrderResponseModel.base"
import { WeeklyPlanRtoModel, WeeklyPlanRtoModelType } from "./WeeklyPlanRtoModel"
import { WeeklyPlanRtoModelSelector } from "./WeeklyPlanRtoModel.base"
import { RootStoreType } from "./index"


/**
 * MutationRootBase
 * auto generated base class for the model MutationRootModel.
 *
 * mutation root
 */
export const MutationRootModelBase = ModelBase
  .named('MutationRoot')
  .props({
    __typename: types.optional(types.literal("mutation_root"), "mutation_root"),
    addDevice: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    addWeeklyPlanItem: types.union(types.undefined, types.late((): any => WeeklyPlanRtoModel)),
    cancelSubscription: types.union(types.undefined, types.late((): any => SubscriptionEntityModel)),
    confirmIdentifierCode: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    createAddress: types.union(types.undefined, types.late((): any => CustomerAddressModel)),
    createCustomerActivity: types.union(types.undefined, types.late((): any => CustomerActivityModel)),
    createPaymentMethod: types.union(types.undefined, types.late((): any => CustomerPaymentModel)),
    deleteAddress: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    deleteCustomerActivity: types.union(types.undefined, types.late((): any => CustomerActivityModel)),
    deleteDevice: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    deletePaymentMethod: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    deleteWeeklyPlanItem: types.union(types.undefined, types.late((): any => WeeklyPlanRtoModel)),
    generatePlan: types.union(types.undefined, types.late((): any => GeneratedPlanModel)),
    likeProduct: types.union(types.undefined, types.late((): any => ProductModel)),
    placeOrder: types.union(types.undefined, types.late((): any => OrderModel)),
    processSubscriptionShipNow: types.union(types.undefined, types.late((): any => OrderModel)),
    pushEvent: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    redeemCustomerCredit: types.union(types.undefined, types.late((): any => RedeemCreditRtoModel)),
    setDefaultPaymentMethod: types.union(types.undefined, types.late((): any => CustomerPaymentModel)),
    swapWeeklyPlanDays: types.union(types.undefined, types.late((): any => WeeklyPlanRtoModel)),
    updateAddress: types.union(types.undefined, types.late((): any => CustomerAddressModel)),
    updateCustomer: types.union(types.undefined, types.late((): any => CustomerProfileModel)),
    updateCustomerActivity: types.union(types.undefined, types.late((): any => CustomerActivityModel)),
    updateCustomerAttributes: types.union(types.undefined, types.late((): any => CustomerProfileModel)),
    updateCustomerProfileStage: types.union(types.undefined, types.late((): any => CustomerProfileModel)),
    updateGeneratedPlan: types.union(types.undefined, types.late((): any => GeneratedPlanModel)),
    updateOrderDeliveryDate: types.union(types.undefined, types.late((): any => UpdateOrderResponseModel)),
    updateSubscriptionDelivery: types.union(types.undefined, types.late((): any => SubscriptionEntityModel)),
    updateSubscriptionDeliveryDate: types.union(types.undefined, types.late((): any => SubscriptionEntityModel)),
    updateSubscriptionFrequency: types.union(types.undefined, types.late((): any => SubscriptionEntityModel)),
    updateSubscriptionFutures: types.union(types.undefined, types.late((): any => SubscriptionEntityModel)),
    updateSubscriptionPayment: types.union(types.undefined, types.late((): any => SubscriptionEntityModel)),
    updateSubscriptionPlans: types.union(types.undefined, types.late((): any => SubscriptionEntityModel)),
    updateWeeklyPlan: types.union(types.undefined, types.late((): any => WeeklyPlanRtoModel)),
    updateWeeklyPlanItem: types.union(types.undefined, types.late((): any => WeeklyPlanRtoModel)),
    upsertCart: types.union(types.undefined, types.late((): any => CartModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MutationRootModelSelector extends QueryBuilder {
  addDevice(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { input: NotificationTokenDto }) { return this.__child(`addDevice`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  addWeeklyPlanItem(builder: string | WeeklyPlanRtoModelSelector | ((selector: WeeklyPlanRtoModelSelector) => WeeklyPlanRtoModelSelector) | undefined, args: { id: string, input: ExtraItemDto }) { return this.__child(`addWeeklyPlanItem`+ (args ? '('+['id', 'input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WeeklyPlanRtoModelSelector, builder) }
  cancelSubscription(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined, args: { input: SubscriptionCancelInput }) { return this.__child(`cancelSubscription`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionEntityModelSelector, builder) }
  confirmIdentifierCode(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { input: VerifyCustomerIdentifierCodeInput }) { return this.__child(`confirmIdentifierCode`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  createAddress(builder: string | CustomerAddressModelSelector | ((selector: CustomerAddressModelSelector) => CustomerAddressModelSelector) | undefined, args: { address: CustomerAddressInput }) { return this.__child(`createAddress`+ (args ? '('+['address'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerAddressModelSelector, builder) }
  createCustomerActivity(builder: string | CustomerActivityModelSelector | ((selector: CustomerActivityModelSelector) => CustomerActivityModelSelector) | undefined, args: { activity: CustomerActivityDto, timezone?: (string | null) }) { return this.__child(`createCustomerActivity`+ (args ? '('+['activity', 'timezone'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerActivityModelSelector, builder) }
  createPaymentMethod(builder: string | CustomerPaymentModelSelector | ((selector: CustomerPaymentModelSelector) => CustomerPaymentModelSelector) | undefined, args: { input: CreatePaymentMethodInput }) { return this.__child(`createPaymentMethod`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerPaymentModelSelector, builder) }
  deleteAddress(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { addressName: string }) { return this.__child(`deleteAddress`+ (args ? '('+['addressName'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  deleteCustomerActivity(builder: string | CustomerActivityModelSelector | ((selector: CustomerActivityModelSelector) => CustomerActivityModelSelector) | undefined, args: { id: string }) { return this.__child(`deleteCustomerActivity`+ (args ? '('+['id'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerActivityModelSelector, builder) }
  deleteDevice(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { input: NotificationTokenDeleteDto }) { return this.__child(`deleteDevice`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  deletePaymentMethod(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { paymentId: string }) { return this.__child(`deletePaymentMethod`+ (args ? '('+['paymentId'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  deleteWeeklyPlanItem(builder: string | WeeklyPlanRtoModelSelector | ((selector: WeeklyPlanRtoModelSelector) => WeeklyPlanRtoModelSelector) | undefined, args: { day: number, id: string, itemId: string }) { return this.__child(`deleteWeeklyPlanItem`+ (args ? '('+['day', 'id', 'itemId'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WeeklyPlanRtoModelSelector, builder) }
  generatePlan(builder: string | GeneratedPlanModelSelector | ((selector: GeneratedPlanModelSelector) => GeneratedPlanModelSelector) | undefined, args: { bucket?: (number | null), cart: GuestCartInput, email?: (string | null), exerciseKey?: (string | null), gender?: (Gender | null), goal: Goal, key?: (string | null), numberOfDays?: (number | null), numberOfMeals?: (number | null), proteinTypes?: ProductProtein[] | null, ranges?: CustomPlanProductRange[] | null, unsubscribeEmail?: (boolean | null), weight?: (number | null) }) { return this.__child(`generatePlan`+ (args ? '('+['bucket', 'cart', 'email', 'exerciseKey', 'gender', 'goal', 'key', 'numberOfDays', 'numberOfMeals', 'proteinTypes', 'ranges', 'unsubscribeEmail', 'weight'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GeneratedPlanModelSelector, builder) }
  likeProduct(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined, args: { input: LikeProductInput }) { return this.__child(`likeProduct`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductModelSelector, builder) }
  placeOrder(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined, args: { input: PlaceOrderInput }) { return this.__child(`placeOrder`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), OrderModelSelector, builder) }
  processSubscriptionShipNow(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined, args: { input: SubscriptionShipNowInput }) { return this.__child(`processSubscriptionShipNow`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), OrderModelSelector, builder) }
  pushEvent(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { input: NotificationEventDto }) { return this.__child(`pushEvent`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  redeemCustomerCredit(builder: string | RedeemCreditRtoModelSelector | ((selector: RedeemCreditRtoModelSelector) => RedeemCreditRtoModelSelector) | undefined, args: { input: CustomerCreditInput }) { return this.__child(`redeemCustomerCredit`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), RedeemCreditRtoModelSelector, builder) }
  setDefaultPaymentMethod(builder: string | CustomerPaymentModelSelector | ((selector: CustomerPaymentModelSelector) => CustomerPaymentModelSelector) | undefined, args: { paymentId: string }) { return this.__child(`setDefaultPaymentMethod`+ (args ? '('+['paymentId'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerPaymentModelSelector, builder) }
  swapWeeklyPlanDays(builder: string | WeeklyPlanRtoModelSelector | ((selector: WeeklyPlanRtoModelSelector) => WeeklyPlanRtoModelSelector) | undefined, args: { id: string, input: SwapWeeklyPlanDto }) { return this.__child(`swapWeeklyPlanDays`+ (args ? '('+['id', 'input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WeeklyPlanRtoModelSelector, builder) }
  updateAddress(builder: string | CustomerAddressModelSelector | ((selector: CustomerAddressModelSelector) => CustomerAddressModelSelector) | undefined, args: { address: CustomerAddressInput, addressName: string }) { return this.__child(`updateAddress`+ (args ? '('+['address', 'addressName'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerAddressModelSelector, builder) }
  updateCustomer(builder: string | CustomerProfileModelSelector | ((selector: CustomerProfileModelSelector) => CustomerProfileModelSelector) | undefined, args: { input: UpdateCustomerInput }) { return this.__child(`updateCustomer`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerProfileModelSelector, builder) }
  updateCustomerActivity(builder: string | CustomerActivityModelSelector | ((selector: CustomerActivityModelSelector) => CustomerActivityModelSelector) | undefined, args: { activity: CustomerActivityDto, id: string }) { return this.__child(`updateCustomerActivity`+ (args ? '('+['activity', 'id'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerActivityModelSelector, builder) }
  updateCustomerAttributes(builder: string | CustomerProfileModelSelector | ((selector: CustomerProfileModelSelector) => CustomerProfileModelSelector) | undefined, args: { input: UpdateCustomerAttributesInput }) { return this.__child(`updateCustomerAttributes`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerProfileModelSelector, builder) }
  updateCustomerProfileStage(builder: string | CustomerProfileModelSelector | ((selector: CustomerProfileModelSelector) => CustomerProfileModelSelector) | undefined, args: { input: UpdateCustomerProfileStageInput }) { return this.__child(`updateCustomerProfileStage`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerProfileModelSelector, builder) }
  updateGeneratedPlan(builder: string | GeneratedPlanModelSelector | ((selector: GeneratedPlanModelSelector) => GeneratedPlanModelSelector) | undefined, args: { key: string, plan: CartPlanInput }) { return this.__child(`updateGeneratedPlan`+ (args ? '('+['key', 'plan'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GeneratedPlanModelSelector, builder) }
  updateOrderDeliveryDate(builder: string | UpdateOrderResponseModelSelector | ((selector: UpdateOrderResponseModelSelector) => UpdateOrderResponseModelSelector) | undefined, args: { input: OrderDeliveryDateInput }) { return this.__child(`updateOrderDeliveryDate`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), UpdateOrderResponseModelSelector, builder) }
  updateSubscriptionDelivery(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined, args: { input: SubscriptionDeliveryInput }) { return this.__child(`updateSubscriptionDelivery`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionEntityModelSelector, builder) }
  updateSubscriptionDeliveryDate(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined, args: { input: SubscriptionDeliveryDateInput }) { return this.__child(`updateSubscriptionDeliveryDate`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionEntityModelSelector, builder) }
  updateSubscriptionFrequency(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined, args: { input: SubscriptionFrequencyInput }) { return this.__child(`updateSubscriptionFrequency`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionEntityModelSelector, builder) }
  updateSubscriptionFutures(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined, args: { input: SubscriptionFuturesInput }) { return this.__child(`updateSubscriptionFutures`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionEntityModelSelector, builder) }
  updateSubscriptionPayment(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined, args: { input: SubscriptionPaymentInput }) { return this.__child(`updateSubscriptionPayment`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionEntityModelSelector, builder) }
  updateSubscriptionPlans(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined, args: { input: SubscriptionPlansInput }) { return this.__child(`updateSubscriptionPlans`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionEntityModelSelector, builder) }
  updateWeeklyPlan(builder: string | WeeklyPlanRtoModelSelector | ((selector: WeeklyPlanRtoModelSelector) => WeeklyPlanRtoModelSelector) | undefined, args: { id: string, input: UpdateWeeklyPlanDto }) { return this.__child(`updateWeeklyPlan`+ (args ? '('+['id', 'input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WeeklyPlanRtoModelSelector, builder) }
  updateWeeklyPlanItem(builder: string | WeeklyPlanRtoModelSelector | ((selector: WeeklyPlanRtoModelSelector) => WeeklyPlanRtoModelSelector) | undefined, args: { id: string, input: ExtraItemDto, itemId: string }) { return this.__child(`updateWeeklyPlanItem`+ (args ? '('+['id', 'input', 'itemId'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WeeklyPlanRtoModelSelector, builder) }
  upsertCart(builder: string | CartModelSelector | ((selector: CartModelSelector) => CartModelSelector) | undefined, args: { cart: CartInput }) { return this.__child(`upsertCart`+ (args ? '('+['cart'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CartModelSelector, builder) }
}
export function selectFromMutationRoot() {
  return new MutationRootModelSelector()
}

export const mutationRootModelPrimitives = selectFromMutationRoot()
