// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsBgRemoveFgType {
  auto="auto",
car="car"
}

/**
* ImgixParamsBgRemoveFgType
*/
export const ImgixParamsBgRemoveFgTypeEnumType = types.enumeration("ImgixParamsBgRemoveFgType", [
        "auto",
  "car",
      ])
