// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ImgixParamsRotType {
  pivot="pivot",
straighten="straighten"
}

/**
* ImgixParamsRotType
*/
export const ImgixParamsRotTypeEnumType = types.enumeration("ImgixParamsRotType", [
        "pivot",
  "straighten",
      ])
