import { useEffect } from 'react';
import { toast } from 'react-toastify';

import type { RootStoreType } from '@/models/root-store';
import { AUTH_NAVIGATION_TARGET, CAMPAIGN_INFO } from '@/shared/constant';
import { removeProfileStageSkipUntil } from '@/utils/helpers';
import { load, remove } from '@/utils/local-storage';
import { trackIdentify, trackSignedOut } from '@/utils/track/tracker.helper';

export const useUserFetch = (
  isSignedIn: boolean | undefined,
  store: RootStoreType,
) => {
  const postCampaignInfo = async () => {
    const campaignInfo = load(CAMPAIGN_INFO);
    if (!campaignInfo) {
      return;
    }
    // post the campaign info to the server
    try {
      await store.customerStore.updateCustomerCampaignInfo(campaignInfo);
      remove(CAMPAIGN_INFO);
    } catch (error) {
      // ignore error for now
    }
  };

  const fetchCustomer = async () => {
    try {
      const isLoggedIn = await store.customerStore.checkCustomerInfo();
      if (isLoggedIn) {
        await store.customerStore.getCustomerInfo();
        await store.customerStore.getCustomerProfile();
        await store.customerStore.getProfileStages();
        sessionStorage.removeItem(AUTH_NAVIGATION_TARGET);
        const { customer } = store.customerStore;
        trackIdentify(customer?._id || '', customer);
        // post the campaign info to the server
        postCampaignInfo();
      } else {
        setTimeout(fetchCustomer, 2000);
      }
    } catch (error) {
      // ignore error for now
      toast.error('Failed to fetch customer info');
    }
  };

  useEffect(() => {
    // fetch customer info once user is signed in
    if (isSignedIn && store && !store.customerStore.isLoggedIn) {
      fetchCustomer();
    }
    // reset customer info once user is signed out
    else if (isSignedIn === false && store.customerStore.isLoggedIn) {
      trackSignedOut();
      removeProfileStageSkipUntil();
      store.resetCustomer();
    }
  }, [isSignedIn]);
};
