import { applySnapshot, Instance, types } from 'mobx-state-tree';
import { withRootStore } from '@/models/helpers/with-root-store';
import { SearchState } from '@/utils/constants';
// can store in env
const SEARCH_MINIMUM_CHARACTERS = 1;

export const SearchStoreModel = types
  .model('SearchStore')
  .props({
    state: types.optional(
      types.enumeration(Object.values(SearchState)),
      SearchState.NULL,
    ),
    text: types.optional(types.string, ''),
    searchResultHeader: types.optional(types.string, ''),
    referrer: types.optional(types.string, ''),
  })
  .extend(withRootStore)
  .views((self) => ({
    get searchState(): SearchState {
      return self.state;
    },
    get isSearchActive() {
      return self.state === SearchState.ACTIVE;
    },
    get isSearchInactive() {
      return self.state === SearchState.INACTIVE;
    },
    get isSearchComplete() {
      return self.state === SearchState.COMPLETE;
    },
    get isSearchNull() {
      return self.state === SearchState.NULL;
    },
    get isSearchPending() {
      return self.state === SearchState.PENDING;
    },
    get isSearchCancelling() {
      return self.state === SearchState.CANCELLING;
    },
    get isValidSearch() {
      return self.text.trim().length >= SEARCH_MINIMUM_CHARACTERS;
    },
    get searchValue() {
      return self.text;
    },
    get searchResultHeaderText() {
      return self.searchResultHeader;
    },
  }))
  .actions((self) => ({
    setSearchState(state: SearchState) {
      self.state = state;
    },
    resetSearchState() {
      applySnapshot(self, { state: SearchState.NULL });
    },
    resetSearch() {
      applySnapshot(self, {
        state: SearchState.NULL,
        text: '',
        searchResultHeader: '',
        referrer: '',
      });
    },
    setText(text: string) {
      self.text = text;
    },
    resetText() {
      self.text = '';
    },
    setSearchResultHeader(header: string) {
      self.searchResultHeader = header;
    },
    setReferrer(referrer: string) {
      self.referrer = referrer;
    },
  }));

export type SearchStoreType = Instance<typeof SearchStoreModel>;
export interface SearchStore extends SearchStoreType {}
