import Button from '@/components/common/Button/Button';

export type ProfileStageFooterProps = {
  title: string;
  detail?: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
};

const ProfileStageFooter: React.FC<ProfileStageFooterProps> = ({
  title,
  detail,
  onClick,
  disabled = false,
  loading = false,
}: ProfileStageFooterProps) => {
  return (
    <div className="sticky bottom-0 left-0 bg-white">
      <hr />
      <div className="flex items-center justify-between px-6 py-4">
        <div className="font-interSemibold text-xl text-grey-midnight">
          {detail}
        </div>
        <Button
          className="min-h-10 min-w-[162px] px-6 py-2"
          onClick={onClick}
          disabled={disabled}
          loading={loading}
          theme="primary"
        >
          {!loading && <span dangerouslySetInnerHTML={{ __html: title }} />}
        </Button>
      </div>
    </div>
  );
};

export default ProfileStageFooter;
