import * as Sentry from '@sentry/nextjs';
import * as _ from 'lodash';
import type { ObservableMap } from 'mobx';
import type { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import { applySnapshot, getSnapshot, onSnapshot, types } from 'mobx-state-tree';
import { createHttpClient, MSTGQLStore, withTypedRefs } from 'mst-gql';
import { useMemo } from 'react';

import type { RootStoreType as ApiRootStoreType } from '@/models/api/RootStore';
import type { CartStoreType } from '@/models/cart/cart-store';
import {
  onPatchCart,
  onPatchFilter,
  reactionCart,
  reactionRestrictedProducts,
} from '@/models/helpers/patch';
import type { ProductStoreType } from '@/models/product/products-store';
import { decodeSFJWTIsb, parseJwt } from '@/utils/api-helpers/parse-jwt';
import { DEFAULT_ERROR_MESSAGE } from '@/utils/constants';

import { RootStore as ApiRootStore } from './api/RootStore';
import { CartStoreModel } from './cart/cart-store';
import { CustomerStoreModel } from './customer/customer-store';
import { GeneralStoreModel } from './general/general-store';
import { ProductStoreModel } from './product/products-store';
import { SubscriptionStoreModel } from './sub/subscription-store';

let store: RootStoreType;
type Refs = {
  productStore: ObservableMap<string, ProductStoreType>;
  api: ObservableMap<string, ApiRootStoreType>;
  cartStore: ObservableMap<string, CartStoreType>;
};
interface StoreOptions {
  initialState?: any;
  ssr?: boolean;
  disableLoadCustomer?: boolean;
}

const defaultStoreOptions: StoreOptions = {
  initialState: null,
  ssr: false,
  disableLoadCustomer: false,
};

export const getClientStorage = async () => {
  const accessToken = await getClerkTokenFromSession();
  if (accessToken) {
    const decodedToken = parseJwt(accessToken);
    const { act: impersonateAgent } = decodedToken;
    if (impersonateAgent) {
      return sessionStorage;
    }
  }
  return localStorage;
};

export const RootStore: any = withTypedRefs<Refs>()(
  MSTGQLStore.named('RootStore')
    .props({
      productStore: types.optional(ProductStoreModel, {}),
      generalStore: types.optional(GeneralStoreModel, {}),
      cartStore: types.optional(CartStoreModel, {}),
      customerStore: types.optional(CustomerStoreModel, {}),
      subscriptionStore: types.optional(SubscriptionStoreModel, {}),
      api: types.optional(ApiRootStore, {}),
    })
    .actions((self) => ({
      resetCustomer() {
        self.customerStore.reset();
        self.subscriptionStore.reset();
        self.cartStore.resetCart();
        self.cartStore.resetBanner();
        self.productStore.search.resetSearch();
        self.productStore.delivery.resetDelivery();
      },
    })),
);

const refreshToken = async (): Promise<boolean> => {
  if (!store || typeof window === 'undefined') return false;
  try {
    const existingAccessToken = sessionStorage.getItem('accessToken');
    if (existingAccessToken) {
      const decodedToken = parseJwt(existingAccessToken);
      const { agent: impersonateAgent } = decodeSFJWTIsb(decodedToken.isb);
      if (impersonateAgent) {
        return false;
      }
    }
    const existingRefreshToken = sessionStorage.getItem('refreshToken');
    const { refreshToken: response } = await store.api.mutateRefreshToken({
      ...(existingRefreshToken && { input: existingRefreshToken }),
    });
    sessionStorage.setItem('accessToken', response.accessToken);
    sessionStorage.setItem('refreshToken', response.refreshToken);
    return true;
  } catch (err) {
    if (sessionStorage.getItem('retryToken')) {
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('retryToken');
    } else {
      // if it failed for the first time retry one more time
      sessionStorage.setItem('retryToken', 'true');
      return refreshToken();
    }
    return false;
  }
};

async function requestMiddleware(request: any) {
  const { operationName } = JSON.parse(request.body);
  // Only handle client side requests
  if (typeof window === 'undefined' || operationName === 'refreshToken')
    return request;

  // @ts-ignore
  const accessToken = await getClerkTokenFromSession();
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
}

function responseMiddleware(response: any) {
  const errors = response?.response?.errors;
  const statusCode = errors?.[0]?.extensions?.response?.statusCode;
  if (statusCode === 403) {
    window.location.href = '/signin';
  }
  if (errors) {
    const error = _.first(errors) as Error;
    const parsedError = handleResponseError(error);
    if (parsedError) {
      throw parsedError;
    }
  }
  return response;
}

function handleResponseError(error: Error) {
  const code = _.get(error, 'extensions.code', '');
  if (_.includes(code, 'remote-schema-error')) {
    return new Error(DEFAULT_ERROR_MESSAGE);
  }
  return null;
}

async function getClerkTokenFromSession() {
  // @ts-ignore
  const accessToken = await window.Clerk?.session?.getToken();
  return accessToken;
}

export function initializeStore(options = defaultStoreOptions) {
  const { initialState, ssr, disableLoadCustomer } = options;
  const BACKEND_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_V2_ENDPOINT || '';

  const nextStore =
    store ??
    RootStore.create(
      {},
      {
        gqlHttpClient: createHttpClient(BACKEND_ENDPOINT, {
          credentials: 'include',
          headers: () => {
            return {
              'x-source': 'web',
              // ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
            };
          },
          requestMiddleware,
          responseMiddleware,
        }),
        ssr,
      },
    );

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here
  if (initialState) {
    applySnapshot(nextStore, initialState);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return nextStore;
  // Create the store once in the client
  if (!store) {
    store = nextStore;
    onPatchFilter(
      store.productStore.filters,
      _.debounce(
        () =>
          store.productStore.getNewFilteredProducts(
            window.location.pathname.includes('search')
              ? store.productStore.search.text
              : undefined,
          ),
        200,
      ),
    );

    const loadInitialApiData = async () => {
      await getClerkTokenFromSession();
      // check local storage for root store
      try {
        const storage = await getClientStorage();
        const rootStore = storage.getItem('rootStore');
        // validation of root store
        if (rootStore) {
          const parsedRootStore = JSON.parse(rootStore);
          if (parsedRootStore.cartStore?.cart?.loading) {
            parsedRootStore.cartStore.cart.loading = false;
          }
          applySnapshot(store, parsedRootStore);
        }
      } catch (err) {
        Sentry.captureException(err);
      }

      // not save cart, customer and products
      onSnapshot(store, () => {
        try {
          const snapshot: RootStoreSnapshotIn = getSnapshot(store);
          const clonedSnapshot: RootStoreSnapshotIn = _.cloneDeep(snapshot);
          delete clonedSnapshot.productStore.swapCategory;
          delete clonedSnapshot.productStore.errorMessage;
          delete clonedSnapshot.productStore.productCollection;
          delete clonedSnapshot.productStore.filters;
          delete clonedSnapshot.productStore.filteredProducts;
          delete clonedSnapshot.productStore.initialFilter;
          delete clonedSnapshot.productStore.isFiltering;
          delete clonedSnapshot.productStore.search;
          delete clonedSnapshot.productStore.menuHierarchy;
          delete clonedSnapshot.subscriptionStore;
          delete clonedSnapshot.customerStore;
          delete clonedSnapshot.generalStore;
          delete clonedSnapshot.cartStore.goalBased.packOverview;
          delete clonedSnapshot.cartStore.cart?.isRefreshing;
          delete clonedSnapshot.api;
          delete clonedSnapshot.__queryCache;

          if (clonedSnapshot.cartStore?.cart?.loading) {
            clonedSnapshot.cartStore.cart.loading = false;
          }

          // storing the root store in local storage
          getClientStorage().then((storage) =>
            storage.setItem('rootStore', JSON.stringify(clonedSnapshot)),
          );
        } catch (err) {
          Sentry.captureException(err);
        }
      });

      onPatchCart(
        store.cartStore.cart,
        _.debounce(() => store.cartStore.postCart(), 2000),
      );

      reactionCart(store.cartStore.cart, store.generalStore);

      reactionRestrictedProducts(store.productStore);

      // async load of all the products
      store.productStore.getProductRestrictionLimits();
      store.productStore.getProducts('view');
      store.productStore.getRestrictedProducts();
      store.productStore.getNewFilteredProducts();
      store.productStore.getMenuHierarchies();
      store.generalStore.getDataVersion();
      store.generalStore.getMegaNavData();
      store.generalStore.getTips();
      store.generalStore.getUiContents();
      store.generalStore.getMenuBuilderData();
      // async load the cart and draftCart
      // fires loading state for skeleton
      // validate both cart and draftCart
      // apply snap shot with either local storage or api

      if (!ssr && !disableLoadCustomer) {
        await store.customerStore.loadCustomer();
      }

      // update store status
      store.generalStore.setReady(true);
    };
    loadInitialApiData();
  }

  return store;
}

export function useStore(options = defaultStoreOptions) {
  const memoStore = useMemo(
    () => initializeStore(options),
    [options.initialState],
  );
  return memoStore;
}

// export type RootStoreType = typeof RootStore
export interface RootStoreType extends Instance<typeof RootStore> {}

export type RootStoreSnapshotIn = SnapshotIn<RootStoreType>;
export type RootStoreSnapshotOut = SnapshotOut<RootStoreType>;
