import '../styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

import {
  ClerkLoaded,
  ClerkProvider,
  GoogleOneTap,
  useAuth,
} from '@clerk/nextjs';
import * as Sentry from '@sentry/nextjs';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { configure } from 'mobx';
import type { NextComponentType } from 'next'; // Import Component type
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { Slide, ToastContainer } from 'react-toastify';

import { AppLoader } from '@/components/appLoader/AppLoader';
import DeliveryRestrictedCheck from '@/components/checkDelivery/DeliveryRestrictedCheck/DeliveryRestrictedCheck';
import { ToplineBanner } from '@/components/common/ToplineBanner/ToplineBanner';
import { Error500 } from '@/components/error/Error500/Error500';
import { Notification } from '@/components/notification/Notification';
import ProfileStageModal from '@/components/subscription/ProfileStage/ProfileStageModal';
import { useUserFetch } from '@/hooks';
import { useStoreCampaignInfo } from '@/hooks/useStoreCampaignInfo';
import { StoreContext } from '@/models/reactUtils';
import { useStore } from '@/models/root-store';
import { CLERK_LOCALIZATION } from '@/utils/clerk-config';

configure({ isolateGlobalState: true });

const AuthGuard = dynamic(() => import('@/utils/authGuard'), { ssr: false });

declare global {
  interface Window {
    Microsoft: any;
    lcw: any;
    posthog: any;
    zE: any;
  }
}

// Add requireAuth to the component type
type CustomAppProps = AppProps & {
  Component: NextComponentType & {
    requireAuth?: boolean;
    disableLoadCustomer?: boolean;
  }; // add auth type
};

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || '',
    person_profiles: 'identified_only',
    capture_pageview: false,
    autocapture: true,
    persistence: 'localStorage+cookie',
  });
  window.posthog = posthog;
}

const MyApp = ({ Component, pageProps }: CustomAppProps) => {
  const store = useStore({
    // @ts-ignore
    initialState: pageProps.initialState,
    disableLoadCustomer: !!Component.disableLoadCustomer,
  });

  const { isSignedIn } = useAuth();

  // extract campaign info
  useStoreCampaignInfo();

  // fetch customer info
  useUserFetch(isSignedIn, store);

  return (
    <PostHogProvider client={posthog}>
      <StoreContext.Provider value={store}>
        <AppLoader />
        <ToplineBanner />
        <Notification />
        <ProfileStageModal />
        <DeliveryRestrictedCheck />
        {Component.requireAuth ? (
          // protected pages
          <AuthGuard>
            <>
              <Component {...pageProps} />

              <ToastContainer
                position="bottom-center"
                theme="colored"
                transition={Slide}
                limit={1}
              />
            </>
          </AuthGuard>
        ) : (
          // public page
          <>
            <Component {...pageProps} />

            <ToastContainer
              position="bottom-center"
              theme="colored"
              transition={Slide}
              limit={1}
            />
          </>
        )}
        <SpeedInsights />
      </StoreContext.Provider>
    </PostHogProvider>
  );
};

const App = ({ pageProps, ...rest }: CustomAppProps) => {
  return (
    <Sentry.ErrorBoundary fallback={<Error500 />}>
      <ClerkProvider
        afterSignOutUrl="/signin"
        localization={CLERK_LOCALIZATION}
        {...pageProps}
      >
        <GoogleOneTap
          signInForceRedirectUrl="/account"
          signUpForceRedirectUrl="/account"
        />
        <ClerkLoaded>
          <MyApp pageProps={pageProps} {...rest} />
        </ClerkLoaded>
      </ClerkProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
