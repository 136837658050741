import Image from 'next/legacy/image';

import ProgressBar from '../ProgressBar/ProgressBar';

export type ProfileStageHeaderProps = {
  onClose?: () => void;
  title?: string;
  percentage?: number;
  hideProgressBar?: boolean;
  transparent?: boolean;
};

const ProfileStageHeader: React.FC<ProfileStageHeaderProps> = ({
  onClose,
  title = '<p/>',
  percentage = 63,
  hideProgressBar,
  transparent,
}: ProfileStageHeaderProps) => {
  const bgColor = transparent ? 'bg-transparent' : 'bg-white';
  return (
    <div className={`sticky left-0 top-0 z-10 ${bgColor}`}>
      <div className="flex items-center justify-between p-4">
        <div dangerouslySetInnerHTML={{ __html: title }} />
        {onClose && (
          <button id="close" onClick={onClose} className="flex items-center">
            <Image
              id="close"
              src={'/assets/icons/close_icon.svg'}
              alt="close"
              width={20}
              height={20}
            />
          </button>
        )}
      </div>
      <div className="mb-6 px-4">
        {!hideProgressBar ? (
          <ProgressBar percentage={percentage} />
        ) : (
          <div className="h-1.5" />
        )}
      </div>
      {!hideProgressBar && <hr />}
    </div>
  );
};

export default ProfileStageHeader;
