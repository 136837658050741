import type { Instance, SnapshotIn } from 'mobx-state-tree';
import {
  applySnapshot,
  cast,
  getSnapshot,
  getType,
  types,
} from 'mobx-state-tree';
import _ from 'lodash';
import { withRootStore } from '@/models/helpers/with-root-store';
import { getFilterFunction } from '@/utils/helpers';

const DEFAULT_TOP_FILTER = '';
export const DEFAULT_SORT = 'featured';
export const DEFAULT_SEARCH_SORT = 'score';

export const SelectedFiltersModel = types
  .model('SelectedFiltersModel')
  .props({
    category: types.optional(types.array(types.string), []),
    menuHierarchy: types.optional(types.array(types.string), []),
    range: types.optional(types.array(types.string), []),
    proteinType: types.optional(types.array(types.string), []),
    carbs: types.optional(types.array(types.string), []),
    calories: types.optional(types.array(types.string), []),
    dietary: types.optional(types.array(types.string), []),
    marketingTag: types.optional(types.array(types.string), []),
  })
  .extend(withRootStore)
  .views((self) => ({
    isFiltered<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>,
    >(key: K, value: T[K]): boolean {
      const filterArray = self[key];
      return filterArray.includes(value);
    },
    isFilterKeyActive<K extends keyof SnapshotIn<typeof self>>(
      key: K,
    ): boolean {
      const filterArray = self[key];
      return (filterArray && filterArray.length > 0) || false;
    },
    isDisabled(
      key: string,
      value: string | Record<string, any> | string[],
    ): boolean {
      const products = self.rootStore.productStore?.filteredProducts;
      if (!products || products.length === 0) {
        // Here, we assume that if there are no products, the filter is disabled
        return true;
      }

      const filterFunction = getFilterFunction(key, value);
      if (!filterFunction) {
        // If key is not recognized, default to not disabled
        return false;
      }

      return !products.some(filterFunction);
    },
    isRangeInSearch(value: string): boolean {
      return !self.rootStore.productStore.rangeSearchResultFilter?.some(
        (filter: any) => filter?.value === value,
      );
    },
  }))

  .actions((self) => {
    let initialState = {};

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      applyFilters(filters: any) {
        applySnapshot(self, filters);
      },
      resetMarketingTag() {
        self.marketingTag = cast([JSON.stringify(DEFAULT_TOP_FILTER)]);
      },
      setFilter<
        K extends keyof SnapshotIn<typeof self>,
        T extends SnapshotIn<typeof self>,
      >(key: K, value: T[K]) {
        if (self.isFiltered(key, value)) {
          const updatedFilter = self[key].filter((v: T[K]) => v !== value);
          self[key] = getType(self[key]).create(updatedFilter);
        } else {
          self[key].push(value);
        }
      },
      setSingleFilter<
        K extends keyof SnapshotIn<typeof self>,
        T extends SnapshotIn<typeof self>,
      >(key: K, value: T[K]) {
        if (self.isFiltered(key, value)) {
          self[key] = getType(self[key]).create([]);
        } else {
          self[key] = [value];
        }
      },
    };
  });

export type SelectedFiltersModelType = Instance<typeof SelectedFiltersModel>;
export type SelectedFilterKey = keyof SnapshotIn<typeof SelectedFiltersModel>;

export const ProductFilterModel = types
  .model('ProductFilter')
  .props({
    sort: types.optional(types.string, DEFAULT_SORT),
    showOnlyLiked: types.optional(types.boolean, false),
    productWhereInput: types.optional(types.frozen(), {}),
    selectedFilters: types.optional(SelectedFiltersModel, {}),
    isFilterOpen: types.optional(types.boolean, false),
    isSortOpen: types.optional(types.boolean, false),
    isRangeOpen: types.optional(types.boolean, false),
    currentSelectedFilter: types.optional(types.string, ''),
    currentCategory: types.optional(types.string, ''),
    currentSubCategory: types.optional(types.string, ''),
    rangeFilterDirty: types.optional(types.boolean, false),
  })
  .extend(withRootStore)
  .volatile(() => ({
    filterKeyLookup: {},
    sortKeyLookup: {},
    isUrlApplied: false,
  }))
  .actions((self) => ({
    setFilterKeyLookup(lookup: any) {
      self.filterKeyLookup = lookup;
    },
    setSortKeyLookup(lookup: any) {
      self.sortKeyLookup = lookup;
    },
    setIsUrlApplied(value: boolean) {
      self.isUrlApplied = value;
    },
    setRangeFilterDirty(value: boolean) {
      self.rangeFilterDirty = value;
    },
    setProductWhereInput(productWhereInput: any) {
      self.productWhereInput = productWhereInput;
    },
    setSort(sort: string) {
      self.sort = sort;
    },
    setCurrentCategory(category: string) {
      self.currentCategory = category;
    },
    setCurrentSubCategory(subCategory: string) {
      self.currentSubCategory = subCategory;
    },
    setCurrentSelectedFilter(value: string) {
      self.currentSelectedFilter = value;
    },
    setShowOnlyLikedFilter(showOnlyLiked: boolean) {
      self.showOnlyLiked = showOnlyLiked;
    },
    clearFilters(options = { isSearch: false }) {
      const filter = self.selectedFilters;
      self.sort = options.isSearch ? DEFAULT_SEARCH_SORT : DEFAULT_SORT;
      filter.reset();
      this.setRangeFilterDirty(false);
    },
    openFilter() {
      self.isFilterOpen = true;
    },
    closeFilter() {
      self.isFilterOpen = false;
    },
    openSort() {
      self.isSortOpen = true;
    },
    closeSort() {
      self.isSortOpen = false;
    },
    openRange() {
      self.isRangeOpen = true;
      document.body.style.overflow = 'hidden';
    },
    closeRange() {
      self.isRangeOpen = false;
      document.body.style.overflow = 'visible';
    },
  }))
  .views((self) => ({
    get filterCount(): number {
      const filter = self.selectedFilters;
      return Object.keys(self.selectedFilters).reduce((acc, key) => {
        return acc + filter[key as keyof typeof filter].length;
      }, 0);
    },
    get filterSelected() {
      return _.pickBy(self.selectedFilters, _.size);
    },
    get filterKeyLookupEmpty(): boolean {
      return Object.keys(self.filterKeyLookup).length === 0;
    },
    get isFiltered(): boolean {
      return (
        this.filterCount > 0 ||
        !self.selectedFilters.marketingTag.includes(
          JSON.stringify(DEFAULT_TOP_FILTER),
        ) ||
        self.sort !== DEFAULT_SORT
      );
    },
    get currentSort() {
      return JSON.parse(self.sort);
    },
    isSortSelected(sort?: string | undefined | null): boolean {
      return self.sort === sort;
    },
    isShowOnlyLikedFilterSelected(): boolean {
      return self.showOnlyLiked;
    },
    checkCurrentCategory(category: string) {
      return (
        self.currentSubCategory === category ||
        self.currentCategory === category
      );
    },
    getCurrentCategory(categoryOptions: string[]) {
      const subCategory = categoryOptions.find(
        (category) => self.currentSubCategory === category,
      );
      if (subCategory) {
        return subCategory;
      }
      return categoryOptions.find(
        (category) => self.currentCategory === category,
      );
    },
  }));

type ProductFilterType = Instance<typeof ProductFilterModel>;
export interface ProductFilter extends ProductFilterType {}
export type ProductFilterKey = keyof SnapshotIn<typeof ProductFilterModel>;
