// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum CustomPlanProductRange {
  BREKKIE="BREKKIE",
DRINKS="DRINKS",
MEALS="MEALS",
SNACKS="SNACKS"
}

/**
* CustomPlanProductRange
*/
export const CustomPlanProductRangeEnumType = types.enumeration("CustomPlanProductRange", [
        "BREKKIE",
  "DRINKS",
  "MEALS",
  "SNACKS",
      ])
