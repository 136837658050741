import { gql } from 'graphql-request';

export const CMS_SEO_SELECTOR = gql`
seoTitle
seoDescription
ogImage {
  url
}
seoBlurb {
  links
  value
  blocks
}
`;

export const LANDING_PAGE_SEO_SELECTOR = gql`
${CMS_SEO_SELECTOR}
faq {
  title
  description
}
`;

export const LANDING_PAGE_SLUGS_SELECTOR = gql`
slug
`;

export const TOPLINE_BANNER_SELECTOR = gql`
id
displayTime
active
text {
  value
  blocks
  links
}
`;

export const CUSTOMER_EMAIL_CHANGE_SELECTOR = gql`
... on CustomerEmailChange {
  newEmail
  oldEmail
}
... on MessageRto {
  message
  status
  title
  type
}
`;

export const GOAL_BASED_QUIZ_SELECTOR = gql`
heading
sections {
  ... on QuizInputSectionRecord {
    inputDefaultValue
    inputName
    key
    questionText
  }
  ... on QuizButtonSectionRecord {
    key
    questionText
    quizAnswers {
      value
      subText
      name
      id
    }
  }
  ... on QuizPlanSectionRecord {
    key
    plans {
      quizAnswers {
        value
        subText
        name
        id
        calorie
        bucket
      }
      questionText
      key
      id
      goal
    }
  }
}
`;

export const MEAL_PLAN_QUIZ_SELECTOR = gql`
step
sections {
  key
  title
  subTitle
  field
  nextStep
  footer
  options {
    method
    nextStep
    subTitle
    title
    value
    description
    extraTag {
      icon {
        url
      }
      text
      backgroundColor {
        hex
      }
    }
    image {
      url
    }
    selectedImage {
      url
    }
    tag {
      text
      icon {
        url
      }
      backgroundColor {
        hex
      }
    }
  }
}`;

export const COUNTDOWN_TIMER_SELECTOR = gql`
sections {
  ... on CountdownSectionRecord {
    __typename
    backgroundImage {
      url
    }
    buttonLink
    buttonText
    buttonTheme
    countdownEndDate
    countdownText
    active
    endedText
  }
}
`;

const MENU_HIERARCHY_SECTION_SELECTOR = gql`
  sections {
    __typename
    id
    ... on ProductBenefitSectionRecord {
      list {
        icon {
          alt
          url
          width
          height
          title
        }
        iconDark {
          alt
          url
          width
          height
          title
        }
        title
        description
      }
    }
    ... on RangeDescriptionSectionRecord {
      description {
        value
        links
        blocks
      }
      title
      titleBackgroundColor {
        hex
      }
      buttonText
    }
  }
`;
const MENU_HIERARCHY_CHILDREN_SELECTOR = gql`
  id
  name
  active
  slug
  category
  productWhereInput
  description
  image {
    url
  }
  defaultSortOption {
    value
  }
  ${MENU_HIERARCHY_SECTION_SELECTOR}
`;

export const MENU_HIERARCHY_SELECTOR = gql`
${MENU_HIERARCHY_CHILDREN_SELECTOR}
childrenCategories {
  ${MENU_HIERARCHY_CHILDREN_SELECTOR}
  childrenCategories {
    ${MENU_HIERARCHY_CHILDREN_SELECTOR}
  }
}
`;

export const SWAP_CONFIGS_CMS_SELECTOR = gql`
goal
swapCategory {
  id
  key
  name
  menuHierarchy {
    slug
  }
}
`;

export const MENU_CATEGORY_SELECTOR = gql`
${CMS_SEO_SELECTOR}
id
name
active
slug
category
description
showOnNavbar
showBulkDiscountBar
productWhereInput
defaultSortOption {
  name
  value
}
image {
  alt
  url
  title
}
description
extraLabel
childrenCategories {
  id
  name
  active
  slug
  extraLabel
  image {
    alt
    url
    title
  }
  childrenCategories {
    id
    name
    active
    slug
    extraLabel
    image {
      alt
      url
      title
    }
  }
}
`;

export const MENU_CATEGORY_LINK_SELECTOR = gql`
id
name
slug
category
`;
