import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import { isLink, isParagraph } from 'datocms-structured-text-utils';
import Link from 'next/link';
import React from 'react';
import { renderMarkRule, renderNodeRule, StructuredText } from 'react-datocms';

interface StructuredTextProps {
  content: any;
}

const ProfileStageStructuredText: React.FC<StructuredTextProps> = ({
  content,
}: StructuredTextProps) => {
  return (
    <div className="mx-auto max-w-2xl break-words">
      <StructuredText
        data={content}
        customNodeRules={[
          renderNodeRule(isParagraph, ({ children, key }) => {
            return (
              <p className="font-interMedium text-xxs text-black" key={key}>
                {children}
              </p>
            );
          }),
          renderNodeRule(isLink, ({ node, key }) => {
            const linkUrl = node.url.replace(
              /https?:\/\/(www\.)?my(muscle|code)chef\.com/,
              '',
            );
            const isInternal = linkUrl.startsWith('/');
            const linkText = toPlainText(node);
            const metaProps = (node.meta || []).reduce(
              (acc, { id, value }) => ({ ...acc, [id]: value }),
              {} as Record<string, string>,
            );
            return (
              <span
                key={key}
                className="cursor-pointer text-attention underline"
              >
                {isInternal && metaProps.target !== '_blank' ? (
                  <Link href={linkUrl} legacyBehavior {...metaProps}>
                    {linkText}
                  </Link>
                ) : (
                  <a href={linkUrl} {...metaProps} rel="noreferrer">
                    {linkText}
                  </a>
                )}
              </span>
            );
          }),
        ]}
        customMarkRules={[
          // convert "strong" marks into <b> tags
          renderMarkRule('strong', ({ children, key }) => {
            return (
              <span className="font-interBold" key={key}>
                {children}
              </span>
            );
          }),
        ]}
      />
    </div>
  );
};

export default ProfileStageStructuredText;
